/*-----------------------------------------------------------------------------------

    Item Name: Snekam Export Trading - Multipurpose eCommerce HTML Template.
    Author: ashishmaraviya
    Version: 2.0
    Copyright 2024

-----------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------
    Included CSS INDEX
-----------------------------------------------------------------------------------

01. Typography

02. Utilities
    - Variables
    - Mixins

03. Components
    - Common

04. layout
    - Header
    - Footer

05. pages 
   - Hero
   - Categories
   - Product-banner
   - Popular-product
   - Deal
   - Testimonial
   - Blog
   - Product-page
   - Shop
   - Blog-classic
   - Blog-details
   - Faq
   - About
   - Services
   - Cart
   - Login
   - Register
   - Elements-products
   - Elements-typography
   - Wishlist
   - Track order
   - Checkout
   - Terms Policy

-----------------------------------------------------------------------------------*/
/* Typography */
/* Typography */
/* Manrope */
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("./../font/manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
/* Poppins */
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("./../font/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
/* Utilities */
/* variables */
/* Font Family */
/* color */
/* border-color */
/* btn shadown */
/* shadow */
/* Mixing */
/* Components */
/* Common css */
body {
  margin: 0;
  background-color: #fff;
  letter-spacing: 0.03rem;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "Poppins, sans-serif";
  color: #7a7a7a;
  line-height: 1.75;
}

.cr-banner {
  margin-bottom: 15px;
  text-align: center;
}
.cr-banner h2 {
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #2b2b2d;
}

.section-title {
  margin-bottom: 30px;
}
.section-title .cr-title {
  margin-bottom: 5px;
  padding: 0 64px;
  font-family: "Manrope", sans-serif;
  font-size: 30px;
  font-weight: 800;
  color: #2b2b2d;
  letter-spacing: 0;
  line-height: 22px;
}

.title-2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-2 .cr-banner {
  margin-bottom: 5px;
}
.title-2 .cr-banner h2 {
  margin: 0;
  text-align: left;
  font-size: 25px;
}
.title-2 .cr-banner-sub-title p {
  width: 100%;
  margin: 0;
  text-align: left;
}
.title-2 #dealend {
  padding: 0;
}
.title-2 .dealend-timer {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
}
.title-2 .dealend-timer .dealend-timer {
  background-color: #f7f7f8;
  border-radius: 5px;
}
.title-2 .dealend-timer .time-block {
  margin-left: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #2b2b2d;
}
.title-2 .dealend-timer .time-block .day {
  margin-left: 5px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 28px;
  color: #2b2b2d;
}
.title-2 .dealend-timer .time-block .time {
  min-width: 20px;
  position: relative;
}
.title-2 .dealend-timer .time-block .dots {
  margin-top: -1px;
  margin-left: 5px;
  color: #2b2b2d;
}
.title-2 .cr-pro-tab-nav li {
  margin-left: 5px;
}
.title-2 .cr-pro-tab-nav li a {
  border: 1px solid #eee;
  border-radius: 5px;
  color: #2b2b2d;
  background: #fff;
}
.title-2 .cr-pro-tab-nav li a.active {
  border: 1px solid #2b2b2d;
}

.cr-banner-sub-title {
  width: 100%;
}
.cr-banner-sub-title p {
  max-width: 600px;
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.bg-off-white {
  background-color: #f7f7f8;
}

.cr-button {
  height: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 8px 22px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.2;
  background-color: #64b496;
  color: #fff;
  border: 1px solid #64b496;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-button:hover {
  background-color: #000;
  border-color: #000;
}

.cr-btn-secondary {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 8px 22px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.2;
  background-color: #fff;
  color: #64b496;
  border: 1px solid #64b496;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-btn-secondary:hover {
  background-color: #64b496;
  color: #fff;
}

.padding-t-100 {
  padding: 100px 0 0 0;
}

.padding-b-100 {
  padding-bottom: 100px !important;
}

.padding-tb-100 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-tb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-30 {
  padding: 30px;
}

.padding-t-50 {
  padding-top: 50px;
}

.padding-b-50 {
  padding-bottom: 50px;
}

.margin-t-50 {
  margin-top: 50px;
}

.margin-tb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.margin-b-100 {
  margin-bottom: 100px;
}

.margin-b-50 {
  margin-bottom: 50px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-minus-24 {
  margin-bottom: -24px;
}

.w-100 {
  width: 100% !important;
}

@-webkit-keyframes shadowRolling {
  0% {
    -webkit-box-shadow: 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  12% {
    -webkit-box-shadow: 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  25% {
    -webkit-box-shadow: 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  36% {
    -webkit-box-shadow: 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0);
  }
  50% {
    -webkit-box-shadow: 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496;
            box-shadow: 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496;
  }
  62% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496;
  }
  75% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496;
  }
  87% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496;
  }
  100% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0);
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0);
  }
}

@keyframes shadowRolling {
  0% {
    -webkit-box-shadow: 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  12% {
    -webkit-box-shadow: 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  25% {
    -webkit-box-shadow: 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0), 0px 0 rgba(121, 99, 224, 0);
  }
  36% {
    -webkit-box-shadow: 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0);
            box-shadow: 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496, 0px 0 rgba(121, 99, 224, 0);
  }
  50% {
    -webkit-box-shadow: 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496;
            box-shadow: 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496, 100px 0 #64b496;
  }
  62% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496, 110px 0 #64b496;
  }
  75% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496, 120px 0 #64b496;
  }
  87% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496;
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 130px 0 #64b496;
  }
  100% {
    -webkit-box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0);
            box-shadow: 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0), 200px 0 rgba(121, 99, 224, 0);
  }
}
.cr-wish-notify,
.cr-compare-notify,
.cr-cart-notify {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 99;
}
.cr-wish-notify p,
.cr-compare-notify p,
.cr-cart-notify p {
  padding: 15px 20px;
  background-color: #fff;
  font-size: 14px;
  color: #000;
  border-bottom: 5px solid #64b496;
  border-radius: 5px;
  display: block;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
}
.cr-wish-notify p i,
.cr-compare-notify p i,
.cr-cart-notify p i {
  margin-right: 5px;
}
.cr-wish-notify a,
.cr-compare-notify a,
.cr-cart-notify a {
  color: #64b496;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.cr-wish-notify a:hover,
.cr-compare-notify a:hover,
.cr-cart-notify a:hover {
  color: #000;
}

#cr-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#cr-overlay .loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  color: #64b496;
  left: -100px;
  -webkit-animation: shadowRolling 2s linear infinite;
          animation: shadowRolling 2s linear infinite;
}

/* Breadcrumb */
.cr-breadcrumb-image {
  width: 100%;
  height: 70px;
  background-color: #e4f2ed;
  z-index: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-breadcrumb-image .cr-breadcrumb-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.cr-breadcrumb-image .cr-breadcrumb-title h2 {
  margin-bottom: 0;
  font-family: "Manrope", sans-serif;
  font-size: 19px;
  line-height: 1;
  font-weight: bold;
  color: #2b2b2d;
}
.cr-breadcrumb-image .cr-breadcrumb-title span {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  color: #7a7a7a;
  text-transform: capitalize;
}
.cr-breadcrumb-image .cr-breadcrumb-title span a {
  color: #64b496;
}

.cr-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.cr-overlay-500 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

/* Tab to top */
a.back-to-top {
  width: 38px;
  height: 38px;
  display: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  cursor: pointer;
  border-radius: 20px;
  background-color: #fff;
  color: #64b496;
  border: 1px solid #64b496;
  text-align: center;
  font-size: 22px;
  line-height: 1.6;
}
a.back-to-top i {
  font-size: 20px;
}
a.back-to-top:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.back-to-top .back-to-top-wrap svg {
  width: 36px;
  height: 36px;
  position: fixed;
  right: 16px;
  bottom: 16px;
}
a.back-to-top .back-to-top-wrap svg path {
  fill: transparent;
  stroke: #64b496;
  stroke-width: 5px;
}

.cr-pagination {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-pagination .pagination {
  margin: 0;
}
.cr-pagination .pagination .disabled span {
  background-color: #f7f7f8;
}
.cr-pagination .page-link {
  color: #777;
  border-color: #e9e9e9;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.cr-pagination .active > .page-link, .cr-pagination .page-link.active {
  background-color: #64b496;
  border-color: #64b496;
  color: #fff;
}

/* Responsive */
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}
@media only screen and (min-width: 1200px) {
  .cr-col-5 {
    width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }
}
@media only screen and (max-width: 1199px) {
  .padding-t-100 {
    padding: 70px 0 0 0;
  }
  .padding-b-100 {
    padding-bottom: 70px !important;
  }
  .padding-tb-100 {
    padding: 70px 0;
  }
  .padding-tb-50 {
    padding: 35px 0;
  }
  .padding-t-50 {
    padding: 35px 0 0 0;
  }
  .padding-b-50 {
    padding-bottom: 35px;
  }
  .margin-t-50 {
    margin-top: 35px;
  }
  .margin-b-100 {
    margin-bottom: 70px;
  }
  .margin-tb-100 {
    margin: 70px 0;
  }
  .margin-b-50 {
    margin-bottom: 35px;
  }
  .cr-banner h2 {
    font-size: 28px;
  }
  .cr-banner-sub-title p {
    width: 80%;
  }
  .cr-breadcrumb-image .cr-breadcrumb-title h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .md-30 {
    margin-bottom: 30px;
  }
  .md-30:last-child {
    margin-bottom: 0px;
  }
  .padding-tb-50 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .padding-t-50 {
    padding-top: 35px;
  }
  .padding-b-50 {
    padding-bottom: 35px;
  }
  .margin-t-50 {
    margin-top: 35px;
  }
  .margin-b-50 {
    margin-bottom: 35px;
  }
  .cr-banner h2 {
    font-size: 25px;
  }
  .cr-breadcrumb-image .cr-breadcrumb-title span {
    margin-top: 4px;
  }
  .cr-banner-sub-title p {
    width: 100%;
  }
  .title-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .title-2 .title-box {
    margin-right: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-banner h2 {
    font-size: 22px;
  }
  .cr-breadcrumb-image .cr-breadcrumb-title h2 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-breadcrumb-image {
    height: 100px;
  }
  .cr-breadcrumb-image .cr-breadcrumb-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .cr-breadcrumb-image .cr-breadcrumb-title h2 {
    margin-bottom: 5px;
    font-size: 20px;
  }
}
/* Footer */
.footer {
  position: relative;
  border-top: 1px solid #e9e9e9;
}
.footer::before {
  width: 70px;
  height: 60px;
  content: "";
  /*background-image: url("../img/blog/shape-3.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -25px;
  right: 100px;
  z-index: 1;
}
.footer::after {
  width: 60px;
  height: 60px;
  content: "";
  /*background-image: url("../img/shape/12.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 200px;
  left: -10px;
  z-index: 1;
}
.footer .container {
  position: relative;
}
.footer .container::before {
  width: 120px;
  height: 60px;
  content: "";
  /*background-image: url("../img/shape/13.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 20px;
  right: -85px;
  z-index: 0;
}

.footer-top {
  position: relative;
  z-index: 3;
}

.cr-footer-logo {
  padding-bottom: 0;
}
.cr-footer-logo .image {
  padding-bottom: 15px;
}
.cr-footer-logo .image img {
  width: 100px;
}
.cr-footer-logo p {
  font-size: 14px;
}

.cr-footer h4.cr-sub-title {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3;
  color: #000;
  margin-bottom: 15px;
}
.cr-footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}
.cr-footer ul li {
  margin-bottom: 12px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 26px;
  color: #777;
  position: relative;
}
.cr-footer ul li a {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  color: #777;
}
.cr-footer ul li a:hover {
  color: #64b496;
}
.cr-footer ul li::after {
  font-family: remixicon;
  font-size: 20px;
  line-height: 1.2;
  position: absolute;
  color: #64b496;
  top: 2px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-footer ul li:last-child {
  margin-bottom: 0px;
}
.cr-footer ul .location-icon {
  margin-top: 24px;
  padding-left: 30px;
}
.cr-footer ul .location-icon::after {
  content: "\ef14";
}
.cr-footer ul .mail-icon {
  padding-left: 30px;
}
.cr-footer ul .mail-icon::after {
  content: "\eefc";
}
.cr-footer ul .phone-icon {
  padding-left: 30px;
}
.cr-footer ul .phone-icon::after {
  content: "\efec";
}
.cr-footer .cr-social-media {
  margin: 22px -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.cr-footer .cr-social-media span {
  margin: 2px;
  font-family: "Poppins, sans-serif";
  font-size: 17px;
  font-weight: 400;
  line-height: 1.625;
  color: #000;
}
.cr-footer .cr-social-media span a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 11px;
  background-color: #fff;
  border: 1px solid #e1dfdf;
  border-radius: 5px;
}
.cr-footer .cr-social-media span a:hover {
  color: #64b496;
}
.cr-footer .cr-payment .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
.cr-footer .cr-payment-image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-footer .cr-payment-image img {
  width: 100%;
  border-radius: 5px;
}
.cr-footer .cr-payment-image .payment-overlay {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.cr-footer .cr-payment-image .payment-overlay::after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0;
  font-family: remixicon;
  content: "\ee66";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
}
.cr-footer .cr-payment-image:hover {
  cursor: pointer;
}
.cr-footer .cr-payment-image:hover .payment-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.cr-footer .cr-payment-image:hover .payment-overlay::after {
  opacity: 1;
}

.cr-search-footer {
  position: relative;
}
.cr-search-footer input {
  width: 100%;
  height: 44px;
  padding: 5px 15px;
  border: 1px solid #e9e9e9;
  outline: none;
  border-radius: 5px;
}
.cr-search-footer input::-webkit-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-search-footer input::-moz-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-search-footer input:-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-search-footer input::-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-search-footer input::placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-search-footer .search-btn {
  width: 50px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-search-footer .search-btn i {
  font-size: 21px;
  color: #000;
}

.cr-last-footer {
  padding: 20px 0;
  border-top: 1px solid #e9e9e9;
  text-align: center;
}
.cr-last-footer p {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #000;
  line-height: 1.2;
}
.cr-last-footer p a {
  color: #64b496;
}

.cr-title-hidden {
  display: none;
}

.cr-heading-res {
  display: none;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-footer ul .location-icon {
    margin-top: 20px;
  }
  .cr-footer ul .mail-icon {
    margin-top: 20px;
  }
  .cr-footer ul .phone-icon {
    margin-top: 20px;
  }
  .cr-footer ul li::after {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1199px) {
  .cr-footer .cr-footer-links {
    max-width: 500px;
  }
  .cr-newsletter {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #e1dfdf;
  }
}
@media only screen and (min-width: 992px) {
  .cr-footer-links.cr-footer-dropdown {
    display: block !important;
  }
}
@media only screen and (max-width: 991px) {
  .cr-sub-title {
    position: relative;
  }
  .cr-footer-logo {
    max-width: 400px;
    margin-bottom: 15px;
  }
  .cr-newsletter {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
  .cr-title-hidden {
    display: block;
  }
  .cr-heading-res {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;
    text-align: right;
    cursor: pointer;
  }
  .cr-heading-res i {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 3;
    color: #000;
  }
  .cr-footer .cr-social-media {
    margin: 40px 0 24px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .cr-footer ul .mail-icon {
    margin-top: 15px;
  }
  .cr-footer ul .phone-icon {
    margin-top: 15px;
  }
  .cr-footer ul .location-icon {
    margin-top: 15px;
  }
  .cr-footer ul li {
    margin: 12px 0;
  }
  .cr-footer ul li::after {
    font-size: 16px;
  }
  .cr-footer ul li:nth-child(1) {
    margin-top: -5px;
  }
  .cr-footer .cr-footer-links {
    margin-top: 24px;
  }
  .cr-footer p {
    padding-bottom: 15px;
  }
  .cr-footer h4.cr-sub-title {
    padding: 15px 0;
    margin-bottom: 0;
    font-size: 15px;
    color: #000;
    border-bottom: 1px solid #e9e9e9;
  }
  .cr-footer-border:not(:last-child) {
    border-right: 0;
  }
  .cr-footer-links {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .cr-footer-border:nth-child(1) {
    border: 0;
  }
  .footer:before, .footer:after {
    display: none;
  }
  .footer .container:before {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .footer:before, .footer:after {
    display: none;
  }
  .footer .container:before {
    display: none;
  }
}
/* Header */
header {
  height: 142px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
}

.cr-fix.menu_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  background: #ffffff;
  -webkit-transition: top 1s;
  transition: top 1s;
}
.cr-fix.menu_fixed .top-header {
  display: none;
}

.cr-fix.menu_fixed_up {
  position: fixed;
  top: -60px;
  left: 0;
  width: 100%;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  background: #ffffff;
  -webkit-transition: top 1s;
  transition: top 1s;
}
.cr-fix.menu_fixed_up .top-header {
  display: none;
}

.cr-menu-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-menu-list .cr-category-dropdown button {
  padding: 12px 30px;
  border: none;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  background-color: #64b496;
  border-radius: 5px;
  color: #fff;
}
.cr-menu-list .cr-category-dropdown button:after {
  margin-left: 40px;
  font-size: 16px;
  vertical-align: 2px;
}
.cr-menu-list .cr-category-dropdown button::before {
  margin-right: 20px;
  font-family: remixicon !important;
  content: "\ef31";
  font-size: 16px;
}
.cr-menu-list .cr-category-dropdown .dropdown-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 235px;
  margin-top: 20px;
  position: absolute;
  visibility: hidden;
  display: block;
  opacity: 0;
}
.cr-menu-list .cr-category-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 1px;
}
.cr-menu-list nav {
  padding: 0;
}
.cr-menu-list nav ul li:not(:last-child) {
  margin-right: 25px;
}
.cr-menu-list nav ul li a {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #777;
}
.cr-menu-list .cr-calling {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  align-items: center;
}
.cr-menu-list .cr-calling i {
  padding-right: 5px;
  font-size: 20px;
}
.cr-menu-list .cr-calling a {
  font-size: 15px;
  font-weight: 500;
}

.top-header {
  padding: 2px 0 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  grid-gap: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  position: relative;
  z-index: 4;
}
.top-header .cr-logo img {
  width: 115px;
  height: 100px;
}
.top-header .cr-search {
  position: relative;
}
.top-header .cr-search select {
  width: 130px;
  height: calc(100% - 2px);
  border: 0;
  position: absolute;
  top: 1px;
  right: 45px;
  font-size: 13px;
  border-left: 1px solid #64b496;
  border-radius: 0;
}
.top-header .cr-search select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.top-header .cr-search input {
  width: 600px;
  height: 45px;
  padding-left: 15px;
  padding-right: 175px;
  border: 1px solid #64b496;
  border-radius: 5px;
  outline: none;
}
.top-header .cr-search input::-webkit-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #7a7a7a;
}
.top-header .cr-search input::-moz-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #7a7a7a;
}
.top-header .cr-search input:-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #7a7a7a;
}
.top-header .cr-search input::-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #7a7a7a;
}
.top-header .cr-search input::placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #7a7a7a;
}
.top-header .cr-search .search-btn {
  width: 45px;
  background-color: #64b496;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.top-header .cr-search .search-btn i {
  font-size: 14px;
  color: #fff;
}
.top-header .cr-right-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.top-header .cr-right-bar ul.navbar-nav li.nav-item .dropdown-toggle:after, .top-header .cr-right-bar ul.navbar-nav li.nav-item .dropdown-toggle:before {
  display: none;
}
.top-header .cr-right-bar ul.navbar-nav .dropdown .dropdown-menu li a {
  color: #777;
  font-size: 13px;
  font-weight: 400;
}
.top-header .cr-right-bar ul.navbar-nav .dropdown .dropdown-menu li a:hover {
  color: #64b496;
}
.top-header .cr-right-bar .cr-right-bar-item {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.top-header .cr-right-bar .cr-right-bar-item:not(:last-child) {
  padding-right: 30px;
}
.top-header .cr-right-bar .cr-right-bar-item:hover {
  color: #64b496;
}
.top-header .cr-right-bar .cr-right-bar-item:hover span {
  color: #64b496;
}
.top-header .cr-right-bar .cr-right-bar-item span {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  color: #000;
}
.top-header .cr-right-bar .cr-right-bar-item i {
  padding-right: 5px;
  font-size: 21px;
  line-height: 17px;
}

.navbar-toggler {
  padding: 7px 14px;
  font-size: 16px;
}

ul.navbar-nav {
  margin: auto;
  position: relative;
  z-index: 3;
}
ul.navbar-nav li.nav-item {
  position: relative;
}
ul.navbar-nav li.nav-item:hover {
  color: #64b496;
}
ul.navbar-nav li.nav-item:hover a.nav-link::before {
  width: 100%;
  background-color: #64b496;
}
ul.navbar-nav li.nav-item a.nav-link {
  padding: 11px 8px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: 500;
  color: #000;
  z-index: 1;
  position: relative;
}
ul.navbar-nav li.nav-item a.nav-link::before {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 2px;
  width: 0;
  margin: 0 auto;
  content: "";
  position: absolute;
  top: auto;
  bottom: -6px;
  background-color: #64b496;
  left: 0;
  right: auto;
}
ul.navbar-nav li.nav-item .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
ul.navbar-nav li.nav-item .dropdown-toggle::after {
  margin-left: 5px;
  font-family: remixicon;
  content: "\ea4e";
  border: none;
  vertical-align: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 17px;
  line-height: 15px;
}
ul.navbar-nav .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-width: 160px;
  margin-top: 35px;
  position: absolute;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: auto !important;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 17.64px 0.36px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 17.64px 0.36px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0;
  display: block;
  z-index: 9;
  border: 1px solid #e9e9e9;
}
ul.navbar-nav .dropdown .dropdown-menu li {
  width: 100%;
  margin-right: 0;
}
ul.navbar-nav .dropdown .dropdown-menu li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 7px 20px;
  background-color: #fff;
  position: relative;
  text-transform: capitalize;
}
ul.navbar-nav .dropdown .dropdown-menu li a:hover {
  color: #64b496;
}
ul.navbar-nav .dropdown .dropdown-menu li a i {
  position: absolute;
  right: 10px;
}
ul.navbar-nav .dropdown:hover .dropdown-menu {
  margin-top: 6px;
  opacity: 1;
  visibility: visible;
}

/* Category Toggle */
.cr-category-icon-block {
  padding: 10px 0;
  position: static !important;
}
.cr-category-icon-block:hover .cr-cat-dropdown {
  margin-top: 10px;
  opacity: 1;
  visibility: inherit;
}

.cr-category-menu {
  position: relative;
}
.cr-category-menu .cr-category-toggle {
  width: 35px;
  height: 35px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-category-menu .cr-category-toggle i {
  font-size: 22px;
  color: #2b2b2d;
  line-height: 0;
  line-height: 14px;
  display: block;
}
.cr-category-menu .cr-category-toggle i.ri-arrow-down-s-line {
  position: absolute;
  right: 5px;
}
.cr-category-menu .cr-category-toggle .cr-category-toggle {
  width: 200px;
  min-height: 50px;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #64b496;
  border-radius: 5px;
  cursor: pointer;
}
.cr-category-menu .cr-category-toggle .cr-category-toggle .cr-angle {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 15px;
  line-height: 0;
}

.cr-cat-dropdown {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 600px;
  margin-top: 35px;
  padding: 15px;
  position: absolute;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  left: 0;
  z-index: 10;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.071);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.071);
  border: 1px solid #e9e9e9;
}
.cr-cat-dropdown .cr-cat-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-cat-dropdown .cr-cat-tab .cr-tab-list {
  min-width: 180px;
  margin-right: 24px;
  border-radius: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-cat-dropdown .cr-cat-tab button,
.cr-cat-dropdown .cr-cat-tab a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
  background-color: #fff;
  font-size: 13px;
  color: #4b5966;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-cat-dropdown .cr-cat-tab button:not(:last-child),
.cr-cat-dropdown .cr-cat-tab a:not(:last-child) {
  margin-bottom: 5px;
}
.cr-cat-dropdown .cr-cat-tab button i,
.cr-cat-dropdown .cr-cat-tab a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}
.cr-cat-dropdown .cr-cat-tab button.active {
  color: #64b496;
  background-color: #fff;
  border-color: #64b496;
}
.cr-cat-dropdown .cr-cat-tab button.active i {
  color: #64b496;
}
.cr-cat-dropdown .cr-cat-tab .tab-content {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.cr-cat-dropdown .cr-cat-tab .tab-content .tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-cat-dropdown .cr-cat-tab .tab-content .tab-list .col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.cr-cat-dropdown .cr-cat-tab .tab-content .tab-list .col ul li a {
  height: auto;
  padding: 5px 0;
  color: #777;
  text-transform: capitalize;
  line-height: 26px;
  font-weight: 400;
  font-size: 13px;
  display: block;
  border: 0;
}
.cr-cat-dropdown .cr-cat-tab .tab-content .cr-col-title {
  height: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: #64b496;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
  display: block;
  border-bottom: 1px solid #e9e9e9;
}
.cr-cat-dropdown .cr-cat-tab .tab-content > :active {
  display: block;
}

/* Mobile-menu */
.cr-side-cart {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 400px;
  height: 100%;
  padding: 15px;
  position: fixed;
  top: 0;
  left: -400px;
  background-color: #fff;
  z-index: 22;
  overflow: auto;
}
.cr-side-cart .cr-menu-title {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 0 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;
}
.cr-side-cart .cr-menu-title .menu-title {
  font-size: 18px;
  font-weight: 600;
  color: #64b496;
}
.cr-side-cart .cr-menu-title .cr-close {
  position: relative;
  border: 0;
  font-size: 30px;
  line-height: 1;
  color: #999;
  background-color: #fff;
}
.cr-side-cart .cr-menu-box {
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #eee;
}
.cr-side-cart .cr-menu-box::-webkit-input-placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.cr-side-cart .cr-menu-box::-moz-placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.cr-side-cart .cr-menu-box:-ms-input-placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.cr-side-cart .cr-menu-box::-ms-input-placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.cr-side-cart .cr-menu-box::placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.cr-side-cart ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.cr-side-cart ul li {
  position: relative;
  line-height: 28px;
}
.cr-side-cart ul li a {
  padding: 12px 0;
  display: block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #444;
  text-decoration: none;
  border-bottom: 1px solid #e9e9e9;
}
.cr-side-cart ul li .menu-toggle {
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  background-color: transparent;
}
.cr-side-cart ul li .menu-toggle::before {
  content: "+";
  font-size: 24px;
  font-weight: 500;
  color: #777;
}
.cr-side-cart ul li .menu-toggle i {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: #444;
}
.cr-side-cart ul li .sub-menu {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: none;
  transition: none;
  display: none;
  min-width: auto;
  position: static;
  top: auto;
  visibility: visible;
  opacity: 1;
}
.cr-side-cart ul li .sub-menu li a {
  padding-left: 20px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  opacity: 0.8;
  font-size: 14px;
}
.cr-side-cart ul li .sub-menu li a:hover {
  opacity: 1;
}

.cr-menu-open {
  left: 0 !important;
}

.navbar-collapse {
  display: none;
}

.cr-sidebar-overlay {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 21;
}

.dark-logo {
  display: none;
}

.logo {
  display: block;
}

.cr-header-buttons {
  display: none;
}
.cr-header-buttons a {
  margin-right: 16px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.cr-header-buttons a i {
  font-size: 20px;
}
.cr-header-buttons a:hover {
  color: #64b496;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .top-header .cr-search input {
    width: 400px;
  }
  .cr-menu-list nav ul li:not(:last-child) {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .cr-menu-list nav ul {
    margin-right: -5px;
  }
  .cr-menu-list nav ul li:not(:last-child) {
    margin-right: 30px;
  }
  .cr-menu-list .dropdown button {
    padding: 10px;
  }
  .cr-menu-list .dropdown button::before {
    margin-right: 5px;
  }
  .cr-menu-list .dropdown button::after {
    margin-left: 5px;
  }
  .cr-menu-list .dropdown .dropdown-menu {
    right: 0;
  }
  .cr-menu-list .cr-calling {
    display: none;
  }
  ul.navbar-nav li.nav-item a.nav-link {
    padding: 8px 0;
  }
  ul.navbar-nav li.nav-item a.nav-link:before {
    bottom: -9px;
  }
  ul.navbar-nav .dropdown:hover .dropdown-menu {
    margin-top: 9px;
  }
}
@media only screen and (max-width: 991px) {
  header {
    height: 133px;
  }
  .cr-menu-list {
    padding: 0;
  }
  .top-header .cr-right-bar {
    display: none;
  }
  .top-header .cr-search input {
    max-width: 350px;
  }
  .cr-menu-list .cr-calling {
    display: none;
  }
  .cr-menu-list nav {
    width: 100%;
  }
  .cr-menu-list nav ul {
    margin: 0;
  }
  ul.navbar-nav .dropdown:hover .dropdown-menu {
    margin-top: 0;
  }
  ul.navbar-nav li.nav-item:hover a.nav-link:before {
    display: none;
  }
  .navbar-toggler {
    padding: 0;
    border: 0;
  }
  .navbar-toggler i {
    font-size: 20px;
  }
  .cr-category-icon-block {
    display: none;
  }
  .cr-header-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .cr-header-buttons a {
    margin: 0;
  }
  .cr-header-buttons a:not(:last-child) {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  header {
    height: 173px;
  }
  .top-header {
    padding: 15px 0;
  }
  .top-header .cr-search input {
    width: 300px;
  }
  .cr-side-cart {
    width: 300px;
    left: -300px;
  }
  ul.navbar-nav .dropdown:hover .dropdown-menu {
    margin-top: 0;
  }
  .top-header {
    display: block;
  }
  .top-header .cr-search {
    max-width: 350px;
    margin: auto;
  }
  .top-header .cr-search input {
    width: 100%;
  }
  .top-header .cr-logo {
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .top-header .cr-logo img {
    width: 100px;
    height: 35px;
  }
}
@media only screen and (max-width: 420px) {
  .cr-side-cart {
    width: 250px;
    left: -250px;
  }
  .top-header .cr-search select {
    display: none;
  }
  .top-header .cr-search input {
    padding-right: 45px;
  }
}
/* layout */
/* Hero */
.cr-hero-banner {
  height: 80vh;
  position: relative;
  z-index: 1;
}
.cr-hero-banner::before {
  width: 50px;
  height: 50px;
  content: "";
  /*background-image: url("../img/shape/2.png");*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  opacity: 0.5;
}
.cr-hero-banner::after {
  width: 100px;
  height: 100px;
  content: "";
  /*background-image: url("../img/shape/5.png");*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 0;
  -webkit-animation: left-right 10s linear infinite;
          animation: left-right 10s linear infinite;
  opacity: 0.5;
}
@-webkit-keyframes left-right {
  0% {
    left: 0px;
  }
  50% {
    left: 50px;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  100% {
    left: 0px;
  }
}
@keyframes left-right {
  0% {
    left: 0px;
  }
  50% {
    left: 50px;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  100% {
    left: 0px;
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.cr-hero-banner .container {
  position: relative;
}
.cr-hero-banner .container::before {
  width: 80px;
  height: 80px;
  content: "";
  /*background-image: url("../img/shape/4.png");*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
  top: -15px;
  left: 500px;
  -webkit-animation: rotate 10s linear infinite;
          animation: rotate 10s linear infinite;
  opacity: 0.5;
}
.cr-hero-banner .container::after {
  width: 100px;
  height: 100px;
  content: "";
  /*background-image: url("../img/shape/3.png");*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -30px;
  right: 500px;
  z-index: 0;
  opacity: 0.5;
}

.section-hero .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 15px !important;
}

.cr-hero-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.cr-slider .swiper-wrapper .swiper-slide-active h5 {
  -webkit-animation: fadeInUp 2s both 1s;
          animation: fadeInUp 2s both 1s;
}
.cr-slider .swiper-wrapper .swiper-slide-active h1 {
  -webkit-animation: fadeInUp 2s both 1.5s;
          animation: fadeInUp 2s both 1.5s;
}
.cr-slider .swiper-wrapper .swiper-slide-active p {
  -webkit-animation: fadeInUp 2s both 2s;
          animation: fadeInUp 2s both 2s;
}
.cr-slider .swiper-wrapper .swiper-slide-active .cr-last-buttons {
  -webkit-animation: fadeInUp 2s both 2.5s;
          animation: fadeInUp 2s both 2.5s;
}
.cr-banner-image-five {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-image: url("../../assets/img/product/papad-banner.jpg");
}
.cr-banner-image-four {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  /* background-image: url("../../assets/img/product/papad-banner.jpg"); */
  background-image: url("../../assets/img/product/Moor-Molega.jpg");
}
.cr-banner-image-one {
  width: 100%;
  /*background-image: url("../img/banner/banner.jpg");*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  /*background-image: url("../../assets/img/banner/banana-leaf-banner.jpg");
  background-image: url("../../assets/img/product/papad-new-1.png");*/
  background-image: url("../../assets/img/product/Brinjal-Vathal-banner.jpg");

}

.cr-banner-image-two {
  width: 100%;
  /*background-image: url("../img/banner/banner-2.jpg");*/
  background-repeat: no-repeat;

  background-image: url("../../assets/img/product/papad-new-2.jpeg");
  background-size: cover;
  background-position: right;
}

.cr-banner-image-three {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/img/banner/ship-banner.jpg");
  background-position: right;
}

.cr-left-side-contain {
  max-width: 550px;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
}
.cr-left-side-contain h5 {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Poppins, sans-serif";
  font-weight: 700;
}
.cr-left-side-contain h5 span {
  margin-right: 5px;
  color: #64b496;
  font-weight: bold;
  border-bottom: 2px solid #64b496;
}
.cr-left-side-contain p {
  font-size: 15px;
}
.cr-left-side-contain h1 {
  margin-bottom: 25px;
  font-size: 55px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  line-height: 68px;
  color: #000;
  position: relative;
}
.cr-left-side-contain .cr-last-buttons {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-left-side-contain .cr-last-buttons a {
  margin-right: 20px;
}
.cr-left-side-contain .cr-last-buttons h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #7a7a7a;
}
.cr-left-side-contain .cr-last-buttons h4 span {
  font-size: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  color: #000;
}

.cr-slider .swiper-pagination-bullet {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 15px;
  height: 15px;
  margin: 0 2.5px !important;
  display: inline-block;
  border-radius: 30px;
  opacity: 1;
  border: 1px solid #64b496;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
}
.cr-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #64b496;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-hero-banner .container::after {
    right: 300px;
  }
  .cr-left-side-contain h1 {
    font-size: 48px;
    line-height: 56px;
  }
}
@media only screen and (max-width: 1199px) {
  .cr-hero-banner {
    height: 600px;
  }
  .cr-hero-banner .container::after {
    right: 300px;
  }
  .cr-left-side-contain {
    max-width: 400px;
    height: 600px;
  }
  .cr-left-side-contain h1 {
    font-size: 40px;
    line-height: 46px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-left-side-contain h5 {
    margin-bottom: 15px;
  }
  .cr-left-side-contain h1 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .cr-left-side-contain .cr-last-buttons {
    margin-top: 18px;
  }
  .cr-hero-banner .container::after {
    right: 200px;
  }
  .cr-hero-banner::after {
    width: 56px;
    height: 50px;
  }
  .cr-hero-banner .container::after {
    width: 60px;
    right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-left-side-contain h1 {
    font-size: 24px;
  }
  .cr-left-side-contain h5 {
    font-size: 14px;
    font-weight: 400;
  }
  .cr-hero-banner {
    height: 500px;
  }
  .cr-hero-banner .container::after {
    right: -80px;
  }
  .cr-left-side-contain {
    max-width: 290px;
    height: 500px;
  }
  .cr-left-side-contain p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-hero-banner .container::after {
    right: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .cr-left-side-contain {
    max-width: 250px;
    height: 400px;
  }
  .cr-left-side-contain h1 {
    margin-bottom: 0;
  }
  .cr-hero-banner {
    height: 400px;
  }
  .cr-slider .swiper-wrapper .swiper-slide p {
    display: none;
  }
}
@media only screen and (max-width: 360px) {
  .cr-left-side-contain {
    height: 350px;
  }
  .cr-hero-banner {
    height: 350px;
  }
  .cr-left-side-contain {
    max-width: 210px;
  }
  .cr-left-side-contain h1 {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 320px) {
  .cr-left-side-contain h1 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .cr-hero-banner::after {
    height: 40px;
    width: 40px;
  }
  .cr-hero-banner .container::after {
    width: 40px;
    height: 40px;
  }
}
/* Sidebar Tools */
/* Side-tool */
.cr-tool-active {
  right: 0 !important;
}

.cr-tool-overlay {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.cr-tool {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: fixed;
  top: 0;
  right: -230px;
  z-index: 20;
  color: #000;
}
.cr-tool .cr-tool-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #000;
}
.cr-tool .cr-tool-btn i {
  font-size: 22px;
}
.cr-tool .cr-tool-btn .btn-cr-tool {
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 30%;
  right: 230px;
  border: none;
  background: none;
  background-color: #373840;
  color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 4px 17.64px 0.36px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 17.64px 0.36px rgba(0, 0, 0, 0.1);
}
.cr-tool .cr-tool-btn .color-variant {
  width: 230px;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #fff;
}
.cr-tool .cr-tool-btn .color-variant .cr-bar-title {
  margin-bottom: 15px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
.cr-tool .cr-tool-btn .color-variant .cr-bar-title h6 {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  color: #2b2b2d;
}
.cr-tool .cr-tool-btn .color-variant .cr-bar-title .close-tools {
  color: #fb5555;
  font-size: 17px;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail {
  padding: 0 15px 15px 15px;
  border-bottom: 1px solid #e9e9e9;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail .heading {
  padding: 15px 0;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail .heading h2 {
  margin-bottom: 0;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: 600;
  color: #2b2b2d !important;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color {
  margin: 0;
  padding: 0;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color li {
  height: 36px;
  width: 36px;
  margin: 5px 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c1 {
  background-color: #64b496;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c2 {
  background-color: #f5885f;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c3 {
  background-color: #ae8d62;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c4 {
  background-color: #3f51b5;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c5 {
  background-color: #f44336;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c6 {
  background-color: #e91e4c;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c7 {
  background-color: #607d8b;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c8 {
  background-color: #5392d2;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c9 {
  background-color: #03492f;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.cr-color .c10 {
  background-color: #9655ca;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.dark-mode {
  margin: 0;
  padding: 0;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.dark-mode li {
  height: 85px;
  width: 85px;
  margin: 5px 5px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.dark-mode li.dark {
  background-color: #000;
  color: #fff;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.dark-mode li.white {
  background-color: #fff;
  color: #000;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.bg-panel {
  margin: 0;
  padding: 0;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.bg-panel li {
  height: 85px;
  width: 85px;
  margin: 5px 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.cr-tool .cr-tool-btn .color-variant .cr-tools-detail ul.bg-panel li img {
  height: 85px;
  width: 85px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

.active-bg-panel {
  position: relative;
}
.active-bg-panel:before {
  width: 20px;
  height: 20px;
  padding: 0;
  font-family: remixicon;
  content: "\eb79";
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #64b496;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.active-dark-mode {
  position: relative;
}
.active-dark-mode:before {
  width: 20px;
  height: 20px;
  padding: 0;
  font-family: remixicon;
  content: "\eb79";
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #64b496;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.active-colors:before {
  padding: 0;
  font-family: remixicon;
  content: "\eb79";
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 36px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}

/* Cart Sidebar */
.cr-cart-overlay {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.cr-cart-view-active {
  right: 0 !important;
}

.cr-cart-view {
  height: 100%;
  position: fixed;
  top: 0;
  right: -350px;
  z-index: 20;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #000;
}
.cr-cart-view .cr-cart-inner {
  width: 350px;
  height: 100%;
  margin: 0;
  padding: 0 20px;
  background-color: #fff;
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-cart-view .cr-cart-inner .cr-cart-top .cr-cart-title {
  margin-bottom: 15px;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
.cr-cart-view .cr-cart-inner .cr-cart-top .cr-cart-title h6 {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  color: #2b2b2d;
}
.cr-cart-view .cr-cart-inner .cr-cart-top .cr-cart-title button {
  color: #fb5555;
  font-size: 20px;
  font-weight: 800;
  background: none;
  border: none;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid #e9e9e9;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .crside_pro_img {
  margin: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 20%;
          flex: 1 0 20%;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .crside_pro_img img {
  max-width: 100%;
  border-radius: 5px;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content {
  padding-left: 15px;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 70%;
          flex: 1 0 70%;
  overflow: hidden;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .cart_pro_title {
  width: 100%;
  padding-right: 30px;
  text-decoration: none;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .cr-cart-qty {
  margin-top: 5px;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .cr-cart-qty .cart-qty-plus-minus {
  margin: 0;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .cart-price {
  margin-top: 5px;
  font-size: 14px;
  display: block;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .cart-price span {
  color: #777;
  font-weight: 700;
  font-size: 16px;
}
.cr-cart-view .cr-cart-inner .cr-cart-top ul.crcart-pro-items li .cr-pro-content .remove {
  padding: 0 9px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  line-height: 15px;
  background-color: #fff;
  color: #fb5555;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom {
  position: relative;
  top: -20px;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom .cart_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom .cart-sub-total {
  margin: 20px 0 10px 0;
  padding-top: 0;
  padding-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #e9e9e9;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom .cart-sub-total table {
  margin: 10px 0 0 0;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom .cart-sub-total table tr td {
  border: none;
}
.cr-cart-view .cr-cart-inner .cr-cart-bottom .cart-sub-total table tr .text-right {
  font-weight: 700;
  text-align: right;
}

.cart-qty-plus-minus {
  width: 80px;
  height: 30px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cart-qty-plus-minus input {
  width: 30px;
  margin: 0;
  padding: 0;
  background: transparent none repeat scroll 0 0;
  color: #444;
  border: medium none;
  float: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 38px;
  height: auto;
  text-align: center;
  outline: none;
}
.cart-qty-plus-minus button {
  height: 25px;
  width: 25px;
  margin-top: -2px;
  border: none;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Deal */
.bg-banner-deal {
  width: 100%;
  height: 600px;
  position: relative;
  /*background-image: url("../img/deal/bg-deal.jpg");*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cr-deal-rightside {
  width: 100%;
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-deal-rightside .cr-deal-content {
  max-width: 500px;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.22);
  border-radius: 5px;
  -webkit-box-shadow: -3px 3px 8px 5px rgba(0, 0, 0, 0.05);
          box-shadow: -3px 3px 8px 5px rgba(0, 0, 0, 0.05);
  text-align: left;
}
.cr-deal-rightside .cr-deal-content span {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 400;
}
.cr-deal-rightside .cr-deal-content span code {
  font-size: 26px;
  font-weight: 900;
  line-height: 1.2;
  color: #64b496;
}
.cr-deal-rightside .cr-deal-content h4.cr-deal-title {
  margin-top: 12px;
  margin-bottom: 15px;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  color: #2b2b2d;
}
.cr-deal-rightside .cr-deal-content p {
  font-size: 14px;
  font-weight: 300;
}

.cr-counter {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.cr-counter .cr-counter-inner {
  padding: 12px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #e9cbbe;
  border-radius: 5px;
}
.cr-counter .cr-counter-inner h4 {
  width: 45px;
  margin: 0;
  position: relative;
  display: -ms-grid;
  display: grid;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: #7a7a7a;
  text-align: center;
}
.cr-counter .cr-counter-inner h4 span {
  font-family: "Poppins, sans-serif";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #2b2b2d;
}
.cr-counter .cr-counter-inner h4:not(:first-child) {
  margin-left: 20px;
}
.cr-counter .cr-counter-inner h4:not(:first-child)::after {
  content: ":";
  font-size: 25px;
  color: #7a7a7a;
  position: absolute;
  right: 50px;
  top: 5px;
}

/* Responsive */
@media only screen and (max-width: 991px) {
  .cr-deal-rightside .cr-deal-content {
    max-width: 400px;
  }
  .cr-deal-rightside .cr-deal-content h4.cr-deal-title {
    font-size: 22px;
  }
  .cr-counter .cr-counter-inner h4 span {
    font-size: 18px;
  }
  .bg-banner-deal, .cr-deal-rightside {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-deal-rightside .cr-deal-content {
    max-width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .cr-deal-rightside .cr-deal-content h4.cr-deal-title {
    font-size: 22px;
  }
  .cr-deal-rightside .cr-deal-content span code {
    font-size: 22px;
  }
  .cr-deal-rightside .cr-deal-content {
    max-width: 100%;
    text-align: center;
  }
  .cr-deal-rightside .cr-deal-content .cr-counter {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media only screen and (max-width: 390px) {
  .cr-products-rightbar .cr-products-rightbar-content .cr-unique {
    display: none;
  }
  .cr-deal-rightside .cr-deal-content {
    padding: 15px;
  }
  .cr-counter .cr-counter-inner h4 {
    font-size: 12px;
  }
  .cr-counter .cr-counter-inner h4 span {
    font-size: 16px;
  }
  .cr-counter .cr-counter-inner h4:not(:first-child) {
    margin-left: 5px;
  }
  .cr-counter .cr-counter-inner h4:not(:first-child)::after {
    font-size: 20px;
    top: 5px;
    right: 43px;
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 17px;
  }
  .cr-products-rightbar .cr-products-rightbar-content .cr-off {
    margin-top: 10px;
  }
  .cr-products-rightbar .cr-products-rightbar-content .cr-off span {
    font-size: 17px;
  }
  .cr-products-rightbar .cr-products-rightbar-content .rightbar-buttons {
    margin-top: 20px;
  }
  .cr-products-leftbar .cr-products-leftbar-content h2 {
    font-size: 20px;
  }
  .cr-products-leftbar .cr-products-leftbar-content h4 {
    font-size: 20px;
  }
  .cr-products-leftbar .cr-products-leftbar-content span {
    font-size: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .bg-banner-deal, .cr-deal-rightside {
    height: 450px;
  }
}
/* Testimonial */
.section-testimonial {
  position: relative;
}
.section-testimonial::before {
  width: 100px;
  height: 100px;
  content: "";
  /*background-image: url("../img/shape/11.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  left: -20px;
  z-index: 0;
}

.cr-testimonial-pt-50 {
  padding-top: 50px;
}

.cr-testimonial {
  margin-top: -6px;
  padding: 65px 15px 20px 15px;
  position: relative;
  background-color: #f7f7f8;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-testimonial::before {
  width: 50px;
  height: 50px;
  content: "";
  /*background-image: url("../img/testimonial/left-quote.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  left: 30px;
  opacity: 0.5;
}
.cr-testimonial::after {
  width: 50px;
  height: 50px;
  content: "";
  /*background-image: url("../img/testimonial/left-quote.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  bottom: 20px;
  right: 30px;
  opacity: 0.5;
}
.cr-testimonial .cr-testimonial-inner {
  max-width: 310px;
  margin: 10px auto 0 auto;
  display: block;
  text-align: center;
}
.cr-testimonial .cr-testimonial-inner span {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 2.357;
  color: #7a7a7a;
}
.cr-testimonial .cr-testimonial-inner h4.title {
  font-family: "Poppins, sans-serif";
  font-size: 18px;
  font-weight: bold;
  line-height: 1.833;
  color: #2b2b2d;
}
.cr-testimonial .cr-testimonial-inner p {
  margin-bottom: 5px;
}
.cr-testimonial .cr-testimonial-inner .cr-star {
  margin-bottom: 5px;
}
.cr-testimonial .cr-testimonial-inner .cr-star i {
  font-size: 16px;
  color: #f5885f;
}
.cr-testimonial .cr-testimonial-image {
  width: 100%;
  position: absolute;
  top: -58px;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-testimonial .cr-testimonial-image img {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  border: 13px solid #fff;
}
.cr-testimonial .cr-testimonial-image::after {
  width: 121px;
  height: 63px;
  content: "";
  position: absolute;
  bottom: 0px;
  border: 1px solid #e9e9e9;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top: none;
  z-index: 0;
}

/* Responsive */
@media only screen and (max-width: 1199px) {
  .cr-testimonial::before {
    width: 40px;
    height: 40px;
    top: 20px;
    left: 20px;
  }
  .cr-testimonial::after {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .section-testimonial::before {
    width: 60px;
    height: 60px;
  }
  .section-testimonial::after {
    width: 100px;
    height: 80px;
  }
  .cr-testimonial {
    margin-top: 36px !important;
  }
  .cr-testimonial::before {
    width: 35px;
    height: 35px;
    top: 10px;
    left: 10px;
  }
  .cr-testimonial::after {
    width: 35px;
    height: 35px;
    bottom: 10px;
    right: 10px;
  }
  .cr-testimonial .cr-testimonial-image {
    top: -50px;
  }
  .cr-testimonial .cr-testimonial-image img {
    width: 90px;
    height: 90px;
  }
  .cr-testimonial .cr-testimonial-image::after {
    width: 92px;
    height: 41px;
    bottom: 0;
  }
  .cr-testimonial .cr-testimonial-inner {
    margin: -13px auto 0 auto;
  }
  .cr-testimonial-pt-100 {
    padding-top: 0;
  }
  .cr-testimonial-pt-50 {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .section-testimonial:before {
    display: none;
  }
}
@media only screen and (max-width: 520px) {
  .cr-testimonial .cr-testimonial-inner h4.title {
    font-size: 15px;
  }
  .cr-testimonial .cr-testimonial-inner p {
    font-size: 14px;
  }
}
/* Popular-product */
.section-popular-product-shape {
  position: relative;
}
.section-popular-product-shape::before {
  width: 80px;
  height: 110px;
  content: "";
  /*background-image: url("../img/product/shape-1.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 270px;
  left: 20px;
  z-index: 0;
}
.section-popular-product-shape .container {
  position: relative;
}
.section-popular-product-shape .container::after {
  width: 110px;
  height: 110px;
  content: "";
  /*background-image: url("../img/shape/10.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  position: absolute;
  bottom: -100px;
  left: -100px;
  z-index: 0;
}

/* popular-product */
.d-product {
  display: none;
}

.cr-product-tabs ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.cr-product-tabs ul .active {
  color: #64b496 !important;
}
.cr-product-tabs ul li {
  padding: 12px 15px;
  position: relative;
  background-color: #f7f7f8;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: bold;
  line-height: 1.667;
  color: #2b2b2d;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
}
.cr-product-tabs ul li::after {
  font-family: remixicon;
  content: "\ea6c";
  font-size: 16px;
  position: absolute;
  right: 13px;
}
.cr-product-tabs ul li:not(:last-child) {
  margin-bottom: 5px;
}

.sticky {
  position: sticky;
  top: 0;
}

.cr-ice-cubes {
  max-height: 500px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
.cr-ice-cubes img {
  width: 100%;
  border-radius: 5px;
}
.cr-ice-cubes .cr-ice-cubes-contain {
  padding: 80px 20px 20px 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.cr-ice-cubes .cr-ice-cubes-contain h4.title {
  margin-bottom: 5px;
  font-family: "Manrope", sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 300;
  line-height: 1.2;
}
.cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
  margin-bottom: 5px;
  font-family: "Manrope", sans-serif;
  text-transform: uppercase;
  font-size: 38px;
  color: #f7e8aa;
  font-weight: 900;
  line-height: 1.2;
}
.cr-ice-cubes .cr-ice-cubes-contain span {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  color: #fff;
}
.cr-ice-cubes .cr-ice-cubes-contain a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
}
.cr-ice-cubes .cr-ice-cubes-contain a:hover {
  background: #000;
  border-color: transparent;
}

.cr-product-slider {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.section-popular {
  position: relative;
}
.section-popular::before {
  width: 80px;
  height: 110px;
  content: "";
  /*background-image: url("../img/shape/9.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 150px;
  right: 20px;
  z-index: 0;
}

.cr-products-rightbar {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-products-rightbar img {
  width: 100%;
  border-radius: 5px;
}
.cr-products-rightbar .cr-products-rightbar-content {
  padding: 24px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-products-rightbar .cr-products-rightbar-content h4 {
  margin-bottom: 0px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.562;
  color: #2b2b2d;
  text-align: right;
}
.cr-products-rightbar .cr-products-rightbar-content .cr-off {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-products-rightbar .cr-products-rightbar-content .cr-off span {
  margin-left: 7px;
  padding: 0 6px;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
  color: #2b2b2d;
}
.cr-products-rightbar .cr-products-rightbar-content .cr-off span code {
  font-size: 14px;
  color: #2b2b2d;
}
.cr-products-rightbar .cr-products-rightbar-content .rightbar-buttons {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.section-popular-product {
  position: relative;
}
.section-popular-product::before {
  width: 100px;
  height: 100px;
  content: "";
  /*background-image: url("../img/product/shape-4.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 0;
}

.cr-products-leftbar {
  position: relative;
}
.cr-products-leftbar img {
  width: 100%;
  border-radius: 5px;
}
.cr-products-leftbar .cr-products-leftbar-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cr-products-leftbar .cr-products-leftbar-content h2 {
  font-family: "Manrope", sans-serif;
  font-size: 44px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  color: #64b496;
}
.cr-products-leftbar .cr-products-leftbar-content h4 {
  font-family: "Poppins, sans-serif";
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #2b2b2d;
}
.cr-products-leftbar .cr-products-leftbar-content span {
  font-family: "Poppins, sans-serif";
  font-size: 20px;
  line-height: 1.2;
  color: #7a7a7a;
}

.cr-popular-product, .cr-twocolumns-product {
  margin: 0 -12px;
}
.cr-popular-product .slick-slide, .cr-twocolumns-product .slick-slide {
  padding: 0 12px;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .d-product {
    display: block;
  }
  .cr-product-tabs ul li:not(:last-child) {
    margin-bottom: 3px;
  }
  .cr-products-rightbar .cr-products-rightbar-content {
    max-width: 200px;
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 28px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h4.title {
    font-size: 28px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
    margin-bottom: 2px;
    font-size: 26px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1199px) {
  .d-product {
    display: none;
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 22px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h4.title {
    font-size: 22px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .cr-product-tabs ul li {
    padding: 12px 15px;
  }
  .cr-product-tabs ul li:not(:last-child) {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-ice-cubes {
    height: 297px;
    overflow: hidden;
    border-radius: 5px;
  }
  .section-popular-product::before {
    width: 80px;
    height: 80px;
  }
  .section-popular-product-shape::before {
    width: 43px;
    height: 60px;
  }
  .section-popular-product-shape .container::after {
    width: 40px;
    height: 40px;
  }
  .section-popular::before {
    width: 40px;
    height: 50px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h4.title {
    font-size: 28px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
    margin-bottom: 3px;
    font-size: 24px;
  }
  .cr-products-rightbar {
    margin-top: 70px;
    display: block;
  }
  .cr-products-rightbar .cr-products-rightbar-content {
    height: calc(100% - 70px);
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 32px;
  }
  .cr-products-leftbar {
    margin-bottom: 24px;
  }
  .cr-product-tabs ul li {
    padding: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-ice-cubes .cr-ice-cubes-contain h4.title {
    font-size: 26px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
    margin-bottom: 0px;
    font-size: 22px;
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-ice-cubes .cr-ice-cubes-contain h4.title {
    font-size: 28px;
  }
  .cr-ice-cubes .cr-ice-cubes-contain h5.sub-title {
    margin-bottom: 2px;
    font-size: 24px;
  }
  .section-popular-product-shape:before {
    display: none;
  }
  .section-popular-product-shape .container:after {
    display: none;
  }
  .section-popular:before {
    display: none;
  }
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 24px;
  }
  .cr-products-rightbar .cr-products-rightbar-content .rightbar-buttons {
    margin-top: 15px;
  }
  .cr-products-leftbar .cr-products-leftbar-content h2 {
    font-size: 30px;
  }
  .cr-products-leftbar .cr-products-leftbar-content h4 {
    font-size: 22px;
  }
  .cr-products-leftbar .cr-products-leftbar-content span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .cr-products-rightbar .cr-products-rightbar-content h4 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 420px) {
  .cr-ice-cubes img {
    height: auto;
  }
}
/* Product-banner */
.section-product-banner {
  position: relative;
}
.section-product-banner::before {
  width: 45px;
  height: 80px;
  content: "";
  /*background-image: url("../img/shape/8.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 130px;
  right: 20px;
  z-index: 0;
}

.cr-product-banner-image {
  position: relative;
}
.cr-product-banner-image img {
  width: 100%;
  border-radius: 5px;
}
.cr-product-banner-image .cr-product-banner-contain {
  padding: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-product-banner-image .cr-product-banner-contain p {
  padding-bottom: 15px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 1.2;
  color: #777;
}
.cr-product-banner-image .cr-product-banner-contain p .percent {
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #64b496;
}
.cr-product-banner-image .cr-product-banner-contain h5 {
  margin-bottom: 15px;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
  color: #2b2b2d;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-product-banner-image .cr-product-banner-contain h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .section-product-banner:before {
    width: 30px;
    height: 55px;
  }
  .cr-product-banner-image .cr-product-banner-contain h5 {
    font-size: 16px;
  }
  .cr-product-banner-image .cr-product-banner-contain p .text {
    display: none;
  }
  .cr-product-banner-image .cr-button {
    height: 32px;
    padding: 8px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-product-banner-image .cr-product-banner-contain h5 {
    font-size: 20px;
  }
  .cr-deal-rightside .cr-deal-content {
    background-color: rgba(247, 247, 248, 0.9) !important;
  }
}
@media only screen and (max-width: 575px) {
  .section-product-banner:before {
    display: none;
  }
}
@media only screen and (max-width: 520px) {
  .cr-product-banner-image .cr-product-banner-contain h5 {
    font-size: 18px;
  }
  .cr-product-banner-image .cr-product-banner-buttons {
    bottom: 15px;
  }
}
@media only screen and (max-width: 420px) {
  .cr-product-banner-image .cr-product-banner-buttons {
    bottom: 50px;
  }
}
@media only screen and (max-width: 360px) {
  .cr-product-banner-image .cr-product-banner-contain h5 {
    margin-bottom: 8px;
    font-size: 16px;
  }
  .cr-product-banner-image .cr-product-banner-contain p {
    padding-bottom: 10px;
  }
}
/* Categories */
.section-categories {
  position: relative;
}
.section-categories::before {
  width: 50px;
  height: 50px;
  content: "";
  /*background-image: url("../img/shape/6.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 0;
}
.section-categories .tab-content {
  width: 100%;
}
.section-categories .tab-content .tab-pane {
  width: 100%;
  height: 100%;
}
.section-categories .tab-content .tab-pane > div {
  height: 100%;
}

.cr-side-categories {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  z-index: 5;
}
.cr-side-categories:hover:after {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
  top: 0;
  left: 0;
}
.cr-side-categories:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 200px;
  height: 200px;
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  position: absolute;
  top: -80px;
  left: -40px;
  z-index: 0;
}
.cr-side-categories img {
  min-width: 100%;
  height: 100%;
}
.cr-side-categories .categories-contain {
  width: 100%;
  padding: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 5;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), color-stop(rgba(0, 0, 0, 0.5)), color-stop(rgba(0, 0, 0, 0.2)), to(transparent));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), transparent);
}
.cr-side-categories .categories-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  z-index: 5;
}
.cr-side-categories .categories-inner h4 {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-size: 54px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.cr-side-categories .categories-inner h4 span {
  padding: 10px 0px 10px 5px;
}
.cr-side-categories .categories-inner h4 span small {
  display: block;
  font-size: 19px;
  font-weight: 400;
  color: #fff;
}
.cr-side-categories .categories-contain .categories-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-side-categories .categories-contain .categories-text h5 {
  margin-bottom: 12px;
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
}
.cr-side-categories .categories-contain .categories-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-side-categories .categories-contain .categories-button a:hover {
  border-color: #e9e9e9;
}

.cr-categories ul.nav-tabs {
  border: none;
}
.cr-categories ul.nav-tabs li.nav-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #f7f7f8;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.cr-categories ul.nav-tabs li.nav-item:not(:last-child) {
  margin-bottom: 5px;
}
.cr-categories ul.nav-tabs li.nav-item button {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  background: none;
}
.cr-categories ul.nav-tabs li.nav-item button:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.cr-categories ul.nav-tabs li.nav-item button:hover {
  color: #64b496;
}
.cr-categories ul.nav-tabs li.nav-item .nav-link.active {
  color: #64b496;
}
.cr-categories ul.nav-tabs li.nav-item .nav-link {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Poppins, sans-serif";
  font-weight: 500;
  font-size: 16px;
}
.cr-categories ul.nav-tabs li.nav-item .nav-link span {
  margin-top: 5px;
}

.center-categories-inner {
  padding: 26px 0;
  text-align: center;
  margin-bottom: 0px;
  font-family: "Manrope", sans-serif;
  color: #2b2b2d;
  font-weight: 700;
  line-height: 1.429;
}
.center-categories-inner span {
  font-size: 12px;
  font-weight: 400;
  color: #7a7a7a;
  vertical-align: text-top;
}

.cr-view-more {
  width: 100%;
  margin: 0;
  padding: 25px 0;
  font-weight: 500;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  color: #64b496 !important;
  font-weight: bold;
  line-height: 1.429;
}

.categories-left-440 {
  position: relative;
  left: -440px;
}

.categories-right-440 {
  position: relative;
  right: -440px;
}

/* Responsive */
@media only screen and (max-width: 1599px) {
  .center-categories-inner {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 1399px) {
  .cr-side-categories .categories-inner h4 {
    font-size: 48px;
  }
  .cr-side-categories .categories-inner h4::after {
    width: 55px;
    height: 55px;
  }
  .cr-side-categories .categories-inner h4 code {
    font-size: 16px;
  }
  .cr-side-categories .categories-inner h4 span small {
    font-size: 17px;
  }
  .center-categories-inner {
    padding: 18px 0;
  }
  .cr-view-more {
    line-height: 27px;
  }
  .categories-left-440 {
    position: relative;
    left: -380px;
  }
  .categories-right-440 {
    position: relative;
    right: -380px;
  }
}
@media only screen and (max-width: 1199px) {
  .cr-side-categories .categories-inner h4 {
    font-size: 42px;
  }
  .cr-side-categories .categories-inner h4::after {
    width: 40px;
    height: 40px;
    top: 55px;
    left: 30px;
  }
  .cr-side-categories .categories-inner h4 ul li {
    font-size: 14px;
  }
  .cr-side-categories .categories-inner h4 span small {
    font-size: 14px;
  }
  .center-categories-inner {
    font-size: 15px;
  }
  .center-categories-inner a {
    font-size: 18px;
  }
  .categories-left-440 {
    position: relative;
    left: -320px;
  }
  .categories-right-440 {
    position: relative;
    right: -320px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-categories ul.nav-tabs {
    margin-bottom: -5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .cr-categories ul.nav-tabs li.nav-item {
    width: calc(50% - 12px);
    margin-bottom: 5px;
  }
  .cr-categories ul.nav-tabs li.nav-item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .cr-side-categories {
    height: 400px;
  }
  .cr-side-categories .categories-inner h4::after {
    width: 50px;
    height: 50px;
  }
  .cr-view-more {
    line-height: 15px;
  }
  .categories-left-440 {
    position: relative;
    left: 0;
  }
  .categories-right-440 {
    position: relative;
    right: 0;
  }
  .section-categories::before {
    width: 30px;
    height: 32px;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-categories:before {
    right: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .section-categories:before {
    display: none;
  }
  .cr-side-categories {
    height: 350px;
  }
  .cr-side-categories:before {
    display: none;
  }
  .cr-side-categories .categories-contain .categories-text h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .cr-side-categories .categories-inner h4 {
    font-size: 34px;
  }
  .cr-side-categories .categories-inner h4 span small {
    font-size: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .cr-side-categories {
    height: 300px;
  }
}
@media only screen and (max-width: 420px) {
  .cr-categories-box {
    width: 100%;
  }
  .cr-side-categories {
    height: 380px;
  }
  .cr-categories ul.nav-tabs li.nav-item {
    width: 100%;
  }
}
/* services- */
.section-services {
  position: relative;
}
.section-services::before {
  width: 80px;
  height: 80px;
  content: "";
  /*background-image: url("../img/shape/7.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 0;
}

.cr-services {
  padding: 24px;
  background-color: #f7f7f8;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cr-services .cr-services-image {
  margin: auto auto 12px auto;
  display: block;
}
.cr-services .cr-services-image i {
  font-size: 43px;
  line-height: 40px;
  color: #64b496;
}
.cr-services .cr-services-contain h4 {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: "Poppins, sans-serif";
  color: #2b2b2d;
  font-weight: bold;
  line-height: 1.667;
  font-weight: 600;
  text-align: center;
}
.cr-services .cr-services-contain p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
}

.cr-services-border .col-3:not(:last-child) {
  border-right: 1px solid #e9e9e9;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-services .cr-services-contain h4 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 991px) {
  .section-services::before {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .cr-services .cr-services-image {
    margin-bottom: 12px;
    padding: 0;
  }
  .cr-services .cr-services-contain {
    text-align: center;
  }
  .cr-services .cr-services-contain h4 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .section-services::before {
    width: 40px;
    height: 40px;
  }
  .section-services:before {
    display: none;
  }
}
/* Blog */
.section-blog {
  position: relative;
}
.section-blog::before {
  width: 70px;
  height: 100px;
  content: "";
  /*background-image: url("../img/blog/shape-1.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 40px;
  right: 50px;
  z-index: 0;
}
.section-blog::after {
  width: 70px;
  height: 100px;
  content: "";
  /*background-image: url("../img/blog/shape-2.png");*/
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 150px;
  left: 40px;
  z-index: 0;
}

.cr-blog-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cr-blog {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  overflow: hidden;
}
.cr-blog:hover .cr-blog-image img {
  -webkit-transform: rotate(3deg) scale(1.1);
          transform: rotate(3deg) scale(1.1);
}
.cr-blog .cr-blog-content {
  padding: 24px;
  text-align: left;
}
.cr-blog .cr-blog-content span {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  color: #7a7a7a;
}
.cr-blog .cr-blog-content span code {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.cr-blog .cr-blog-content h5 {
  margin-top: 5px;
  font-family: "Poppins, sans-serif";
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: #2b2b2d;
}
.cr-blog .cr-blog-content a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.875;
  color: #777;
}
.cr-blog .cr-blog-content a:hover {
  color: #64b496;
}
.cr-blog .cr-blog-content .read {
  position: relative;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.875;
  color: #64b496;
}
.cr-blog .cr-blog-content .read::after {
  position: absolute;
  font-family: remixicon;
  content: "\ea6c";
  font-size: 16px;
  line-height: 1.2;
  top: 0;
  right: -20px;
}
.cr-blog .cr-blog-image {
  position: relative;
  overflow: hidden;
}
.cr-blog .cr-blog-image img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.cr-blog .cr-blog-image .cr-blog-date {
  padding: 15px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 2;
}
.cr-blog .cr-blog-image .cr-blog-date span {
  font-family: "Manrope", sans-serif;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.1;
  color: #fff;
  display: -ms-grid;
  display: grid;
  text-align: center;
}
.cr-blog .cr-blog-image .cr-blog-date span code {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

/* Responsive */
@media only screen and (max-width: 1199px) {
  .cr-blog .cr-blog-content h5 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .section-blog::before {
    width: 50px;
    height: 60px;
  }
  .section-blog::after {
    width: 50px;
    height: 60px;
  }
  .cr-blog .cr-blog-content h5 {
    font-size: 15px;
  }
  .cr-blog .cr-blog-image .cr-blog-date {
    bottom: 15px;
    right: 15px;
  }
  .cr-blog .cr-blog-image .cr-blog-date span {
    font-size: 17px;
  }
  .cr-blog .cr-blog-image .cr-blog-date span code {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-blog .cr-blog-content h5 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-blog .cr-blog-content h5 {
    font-size: 18px;
  }
  .section-blog:before, .section-blog:after {
    display: none;
  }
}
/* Blog */
.quickview-modal .modal-dialog {
  height: 100%;
  margin: 0% auto;
  max-width: 960px;
  width: 960px;
  padding: 35px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.quickview-modal .modal-body {
  padding: 24px !important;
}

.cr-close-model {
  width: 5px;
  height: 5px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;
}

.zoom-image-hover {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border: 1px solid #e9e9e9;
  background-color: #f7f7f8;
  border-radius: 5px;
  cursor: crosshair;
}
.zoom-image-hover img {
  width: 100%;
}

.modal-border-image {
  border: 1px solid #e9e9e9;
}

.cr-size-and-weight-contain {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
}
.cr-size-and-weight-contain p {
  font-size: 14px;
}
.cr-size-and-weight-contain h2.heading {
  margin: 0 0 15px 0;
  display: block;
  color: #2b2b2d;
  font-size: 22px;
  text-decoration: none;
  line-height: 1.5;
  font-weight: 500;
}

.cr-size-and-weight {
  padding-top: 20px;
}
.cr-size-and-weight .cr-product-price {
  padding-top: 20px;
}
.cr-size-and-weight .cr-product-price span.new-price {
  font-family: "Poppins, sans-serif";
  font-size: 24px;
  font-weight: 600;
  line-height: 1.167;
  color: #64b496;
}
.cr-size-and-weight .cr-product-price span.old-price {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  text-decoration: line-through;
  line-height: 1.75;
  color: #999;
}
.cr-size-and-weight .cr-review-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-size-and-weight .cr-review-star p {
  font-size: 14px;
}
.cr-size-and-weight .cr-review-star .cr-star {
  margin-right: 10px;
}
.cr-size-and-weight .cr-review-star .cr-star i {
  font-size: 16px;
  color: #f5885f;
}
.cr-size-and-weight .cr-size-weight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 20px;
}
.cr-size-and-weight .cr-size-weight h5 {
  margin-bottom: 0;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  line-height: 1.556;
  color: #2b2b2d;
}
.cr-size-and-weight .cr-size-weight .cr-kg {
  padding-left: 10px;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul .active-color {
  background-color: #64b496;
  color: #fff;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul li {
  margin: 2px;
  padding: 5px 10px;
  font-family: "Poppins, sans-serif";
  font-size: 12px;
  line-height: 1;
  background-color: #fff;
  color: #777;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.cr-size-and-weight .cr-add-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}
.cr-size-and-weight .cr-add-card .cr-qty-main {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cr-size-and-weight .cr-add-card .cr-qty-main input {
  height: 40px;
  width: 40px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-size-and-weight .cr-add-card .cr-qty-main button {
  height: 18px;
  width: 18px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  line-height: 0;
}
.cr-size-and-weight .cr-add-card .cr-qty-main .minus {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cr-size-and-weight .cr-add-card .cr-add-button {
  margin-left: 15px;
}

@media only screen and (max-width: 991px) {
  /* model */
  .quickview-modal .modal-dialog {
    max-width: 720px;
    width: 720px;
    height: auto;
  }
  .cr-size-and-weight-contain h2.heading {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  /* model */
  .quickview-modal .modal-dialog {
    width: 100%;
    height: auto;
    max-width: 500px;
    padding: 30px 15px;
    margin: 0 auto;
  }
  .cr-size-and-weight-contain {
    margin-top: 24px;
  }
}
@media only screen and (max-width: 380px) {
  .cr-size-and-weight .cr-size-weight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .cr-size-and-weight .cr-size-weight .cr-kg {
    padding: 10px 0 0 0;
  }
}
/* pages */
/* Products-page */
.cr-product-card {
  height: 100%;
  padding: 12px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cr-product-card:hover .cr-product-image .cr-side-view {
  right: 12px;
  opacity: 1;
}
.cr-product-card .cr-product-image {
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.cr-product-card .cr-product-image .cr-side-view {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  z-index: 20;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  top: 15px;
  right: -40px;
  display: -ms-grid;
  display: grid;
  opacity: 0;
}
.cr-product-card .cr-product-image .cr-side-view a {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 100%;
}
.cr-product-card .cr-product-image .cr-side-view a:last-child {
  margin-top: 5px;
}
.cr-product-card .cr-product-image .cr-side-view a i {
  font-size: 18px;
  line-height: 10px;
}
.cr-product-card .cr-product-image .cr-side-view .wishlist.active {
  background-color: #64b496;
  color: #fff;
}
.cr-product-card .cr-product-image .cr-image-inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}
.cr-product-card .cr-product-image .cr-image-inner img {
  width: 100%;
  border-radius: 5px;
}
.cr-product-card .cr-product-image .cr-shopping-bag {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: -16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f7f7f8;
  border: 1px solid #e9e9e9;
  border-radius: 100%;
}
.cr-product-card .cr-product-image .cr-shopping-bag i {
  color: #64b496;
}
.cr-product-card .cr-product-image .cr-shopping-bag.active {
  background-color: #64b496;
  border: 1px solid #64b496;
}
.cr-product-card .cr-product-image .cr-shopping-bag.active i {
  color: #fff;
}
.cr-product-card .cr-product-image .cr-remove-product {
  margin-bottom: 5px !important;
  background-color: #000 !important;
  color: #fff;
}

.cr-product-details {
  padding-top: 24px;
  text-align: center;
  overflow: hidden;
}
.cr-product-details .cr-brand a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 5px;
  font-size: 13px;
  color: #777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-product-details .cr-brand a:hover {
  color: #64b496;
}
.cr-product-details .cr-star {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-product-details .cr-star p {
  margin-left: 5px;
  color: #999;
  font-size: 11px;
  line-height: 10px;
}
.cr-product-details .cr-star i {
  margin: 0 1px;
  font-size: 15px;
  color: #f5885f;
}
.cr-product-details a.title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 12px;
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #2b2b2d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-product-details a.title:hover {
  color: #64b496;
}
.cr-product-details p.cr-price {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  line-height: 1.75;
}
.cr-product-details p.cr-price .new-price {
  color: #64b496;
  font-weight: 700;
}
.cr-product-details p.cr-price .old-price {
  margin-left: 5px;
  font-size: 13px;
  text-decoration: line-through;
}

.cr-product-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}
.cr-product-info ul {
  margin: 0;
  padding: 0;
}
.cr-product-info ul li {
  padding: 5px 0;
  list-style: none;
  font-size: 14px;
  color: #777;
}

.product-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.product-tabs .nav-tabs {
  border: none;
  display: block;
}
.product-tabs .nav-tabs .nav-link {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0;
  border: none;
  background-color: #fff;
  border-radius: 5px;
}
.product-tabs .nav-tabs .nav-link img {
  width: 60px;
  height: 60px;
  padding: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.product-tabs .nav-tabs .nav-link.active {
  border: none;
}
.product-tabs .tab-content {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.cr-size-and-weight-contain {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
}
.cr-size-and-weight-contain h2.heading {
  margin: 0 0 15px 0;
  display: block;
  color: #2b2b2d;
  font-size: 22px;
  text-decoration: none;
  line-height: 1.5;
  font-weight: 500;
}
.cr-size-and-weight-contain p {
  font-size: 14px;
}

.cr-size-and-weight {
  padding-top: 20px;
}
.cr-size-and-weight .list ul {
  margin-top: 15px;
  padding: 0;
}
.cr-size-and-weight .list ul li {
  padding: 5px 0;
  color: #777;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-size-and-weight .list ul li label {
  min-width: 100px;
  margin-right: 10px;
  color: #2b2b2d;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-size-and-weight .cr-review-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-size-and-weight .cr-review-star .cr-star {
  margin-right: 10px;
}
.cr-size-and-weight .cr-review-star .cr-star i {
  font-size: 16px;
  color: #f5885f;
}
.cr-size-and-weight .cr-review-star p {
  font-size: 15px;
}
.cr-size-and-weight .cr-product-price {
  padding-top: 20px;
}
.cr-size-and-weight .cr-product-price span.new-price {
  font-family: "Poppins, sans-serif";
  font-size: 24px;
  font-weight: 600;
  line-height: 1.167;
  color: #64b496;
}
.cr-size-and-weight .cr-product-price span.old-price {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  text-decoration: line-through;
  line-height: 1.75;
  color: #7a7a7a;
}
.cr-size-and-weight .cr-size-weight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 20px;
}
.cr-size-and-weight .cr-size-weight h5 {
  margin-bottom: 0;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  line-height: 1.556;
  color: #2b2b2d;
}
.cr-size-and-weight .cr-size-weight .cr-kg {
  padding-left: 10px;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul li {
  margin: 2px;
  padding: 5px 10px;
  font-family: "Poppins, sans-serif";
  font-size: 12px;
  line-height: 1;
  background-color: #fff;
  color: #777;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.cr-size-and-weight .cr-size-weight .cr-kg ul .active-color {
  background-color: #64b496;
  color: #fff;
}
.cr-size-and-weight .cr-add-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}
.cr-size-and-weight .cr-add-card .cr-qty-main {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cr-size-and-weight .cr-add-card .cr-qty-main input {
  height: 40px;
  width: 40px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-size-and-weight .cr-add-card .cr-qty-main button {
  height: 18px;
  width: 18px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  line-height: 0;
}
.cr-size-and-weight .cr-add-card .cr-qty-main .minus {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cr-size-and-weight .cr-add-card .cr-add-button {
  margin-left: 15px;
}
.cr-size-and-weight .cr-add-card .cr-card-icon {
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-size-and-weight .cr-add-card .cr-card-icon a {
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.cr-size-and-weight .cr-add-card .cr-card-icon a i {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 22px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-size-and-weight .cr-add-card .cr-card-icon a i:hover {
  background-color: #64b496;
  color: #fff;
}

.thumb-image {
  margin: 0 -6px;
}

.thumbnail-image .thumbImg {
  margin: 0 6px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.thumbnail-image .thumbImg img {
  width: 100%;
  padding: 2px;
  border-radius: 5px;
}

.vehicle-detail-banner {
  height: 100%;
}
.vehicle-detail-banner .banner-slider {
  position: sticky;
  top: 30px;
}

.zoom-image-hover {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  border: 1px solid #e9e9e9;
  background-color: #f7f7f8;
  border-radius: 5px;
  cursor: crosshair;
}
.zoom-image-hover img {
  width: 100%;
  display: block;
  margin: auto;
}

.vehicle-detail-banner .banner-slider .slider-for {
  padding-bottom: 15px;
}

.cr-paking-delivery {
  margin-top: 40px;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-paking-delivery .nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.cr-paking-delivery .nav-tabs .nav-item {
  margin-right: 30px;
  position: relative;
}
.cr-paking-delivery .nav-tabs .nav-item .nav-link {
  margin-bottom: 25px;
  padding: 0;
  font-family: "Poppins, sans-serif";
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  color: #2b2b2d;
  border: 0;
  text-align: left;
}
.cr-paking-delivery .nav-tabs .nav-item .nav-link::after {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 2px;
  width: 0;
  margin: 0 auto;
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  background-color: #64b496;
  left: 0;
  right: auto;
}
.cr-paking-delivery .nav-tabs .nav-item .nav-link:hover::after {
  width: 100%;
  background-color: #64b496;
}
.cr-paking-delivery .nav-tabs .nav-item .nav-link.active {
  color: #64b496;
}
.cr-paking-delivery .nav-tabs .nav-item .nav-link.active::after {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 2px;
  width: 100%;
  margin: 0 auto;
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  background-color: #64b496;
  left: 0;
  right: auto;
}
.cr-paking-delivery .cr-tab-content .list ul {
  margin-top: 30px;
  margin-bottom: -5px;
  padding: 0;
}
.cr-paking-delivery .cr-tab-content .list ul li {
  padding: 5px 0;
  color: #777;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-paking-delivery .cr-tab-content .list ul li label {
  min-width: 100px;
  margin-right: 10px;
  color: #2b2b2d;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-paking-delivery .cr-tab-content .cr-description {
  padding-top: 30px;
}
.cr-paking-delivery .cr-tab-content .cr-description p {
  font-size: 14px;
  text-align: left;
}
.cr-paking-delivery .cr-tab-content h4.heading {
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: #2b2b2d;
  border-bottom: 1px solid #e9e9e9;
}

.cr-tab-content-from {
  padding-top: 30px;
}
.cr-tab-content-from h4.heading {
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2b2b2d;
  padding-bottom: 10px;
}
.cr-tab-content-from .cr-ratting-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-tab-content-from .cr-ratting-star span {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
  line-height: 1.75;
  margin-right: 10px;
}
.cr-tab-content-from .cr-ratting-star .cr-t-review-rating {
  margin-bottom: 20px;
}
.cr-tab-content-from .cr-ratting-star .cr-t-review-rating i {
  font-size: 19px;
  letter-spacing: -5px;
}
.cr-tab-content-from .cr-ratting-star .cr-t-review-rating .ri-star-s-fill {
  color: #f5885f;
}
.cr-tab-content-from .cr-ratting-star .cr-t-review-rating .ri-star-s-line {
  color: #999;
}
.cr-tab-content-from .cr-ratting-input:not(:last-child) {
  margin-bottom: 10px;
}
.cr-tab-content-from .cr-ratting-input input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  padding: 5px 20px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  color: #777;
  font-size: 14px;
}
.cr-tab-content-from .cr-ratting-input textarea {
  height: 150px;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  color: #777;
  outline: none;
}
.cr-tab-content-from .post {
  margin-bottom: 30px;
}
.cr-tab-content-from .post .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-tab-content-from .post .content img {
  width: 50px;
  height: 50px;
  margin-right: 24px;
  border-radius: 5px;
}
.cr-tab-content-from .post .content .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cr-tab-content-from .post .content .details .date {
  margin-bottom: 10px;
  font-size: 13px;
  color: #777;
}
.cr-tab-content-from .post .content .details .name {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 17px;
}
.cr-tab-content-from .post p {
  padding-left: 74px;
}
.cr-tab-content-from .post .cr-t-review-rating {
  margin-left: auto;
  margin-bottom: 20px;
}
.cr-tab-content-from .post .cr-t-review-rating i {
  font-size: 19px;
  letter-spacing: 0;
}
.cr-tab-content-from .post .cr-t-review-rating .ri-star-s-fill {
  color: #f5885f;
  letter-spacing: -5px;
}
.cr-tab-content-from .post .cr-t-review-rating .ri-star-s-line {
  color: #999;
}

.modal-border-image {
  border: 1px solid #e9e9e9;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-paking-delivery .nav-tabs .nav-item .nav-link {
    font-size: 18px;
  }
  .cr-size-and-weight-contain h2.heading {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1199px) {
  .cr-product-details {
    padding-top: 20px;
  }
  .cr-product-details h5 {
    margin-bottom: 0;
    font-size: 15px;
  }
  .cr-product-details p.cr-price {
    font-size: 14px;
  }
  .cr-product-details p.cr-price .old-price {
    font-size: 12px;
  }
  .cr-size-and-weight .cr-size-weight h5 {
    min-width: 100px;
    font-size: 14px;
  }
  .cr-size-and-weight .cr-size-weight .cr-kg ul li {
    font-size: 12px;
    margin-right: 5px;
  }
  .cr-add-button .cr-button {
    padding: 8px 15px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-product-card .cr-product-image .cr-side-view {
    right: 12px;
  }
  .cr-product-card .cr-product-image .cr-image-inner {
    pointer-events: none;
  }
  .product-tabs {
    margin-bottom: 24px;
  }
  .cr-size-and-weight-contain h2.heading {
    font-size: 20px;
  }
  .cr-products-categories {
    max-width: 350px;
    margin: auto;
  }
}
@media only screen and (max-width: 767px) {
  .cr-paking-delivery .nav-tabs .nav-item .nav-link {
    font-size: 16px;
  }
  .cr-products-categories {
    max-width: 300px;
    margin: auto;
  }
  .cr-size-and-weight .cr-product-price span.new-price {
    font-size: 22px;
  }
  .cr-size-and-weight-contain {
    margin-top: 24px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-paking-delivery .nav-tabs .nav-item .nav-link {
    margin-bottom: 15px;
  }
  .cr-paking-delivery .nav-tabs .nav-item .nav-link.active:after {
    display: none;
  }
  /* model */
  .cr-model .cr-modal-dialog {
    max-width: 400px;
    width: 400px;
  }
  .cr-product-card-block .col-lg-4 .cr-product-card {
    max-width: 100%;
  }
  .product-tabs {
    display: block;
  }
  .product-tabs .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cr-tab-content-from .post p {
    padding: 0;
  }
  .cr-tab-content-from .post .cr-t-review-rating {
    margin: 0 0 24px 0;
  }
  .cr-tab-content-from .post .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .cr-tab-content-from .post .content img {
    margin: 0 0 24px 0;
  }
  .cr-size-and-weight .cr-product-price span.new-price {
    font-size: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .cr-product-box,
  .cr-product-card {
    width: 100%;
  }
  .banner-480 {
    display: none;
  }
}
@media only screen and (max-width: 380px) {
  .cr-add-button {
    display: none;
  }
  .cr-size-and-weight .cr-review-star p {
    display: none;
  }
  .cr-size-and-weight .cr-size-weight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .cr-size-and-weight .cr-size-weight .cr-kg {
    padding: 10px 0 0 0;
  }
}
/* Shop */
.cr-shop-bredekamp {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f7f7f8;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-shop-bredekamp .cr-toggle {
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-shop-bredekamp .cr-toggle a {
  height: 35px;
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-shop-bredekamp .cr-toggle .shop_side_view {
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-shop-bredekamp .cr-toggle .shop_side_view i {
  font-size: 20px;
}
.cr-shop-bredekamp .cr-toggle .gridCol {
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-shop-bredekamp .cr-toggle .gridCol i {
  font-size: 20px;
}
.cr-shop-bredekamp .cr-toggle .gridRow {
  border: none;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-shop-bredekamp .cr-toggle .gridRow i {
  font-size: 20px;
}
.cr-shop-bredekamp .center-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.cr-shop-bredekamp .center-content span {
  padding: 0 12px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 1.875;
  color: #7a7a7a;
}
.cr-shop-bredekamp .cr-select {
  height: 35px;
  margin: 5px;
  padding: 3px 0px 3px 15px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-shop-bredekamp .cr-select label {
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  line-height: 1.7;
  color: #7a7a7a;
}
.cr-shop-bredekamp .cr-select select {
  padding: 0 2rem 0 0.8rem;
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  background-size: 10px;
  line-height: 1.2;
  color: #7a7a7a;
  width: auto;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* right-view */
.cr-shop-sideview {
  padding: 24px;
  background-color: #f7f7f8;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: sticky;
  top: 30px;
}

h4.cr-shop-sub-title {
  margin-bottom: 0px;
  padding-bottom: 10px;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #2b2b2d;
  text-transform: capitalize;
  border-bottom: 1px solid #e9e9e9;
}

.cr-checkbox {
  padding-top: 28px;
}
.cr-checkbox .checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.cr-checkbox .checkbox-group:not(:last-child) {
  margin-bottom: 15px;
}
.cr-checkbox .checkbox-group input {
  height: initial;
  width: initial;
  margin-bottom: 0;
  padding: 0;
  display: none;
  cursor: pointer;
}
.cr-checkbox .checkbox-group input:checked + label:after {
  width: 6px;
  height: 9px;
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  border: solid #64b496;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.cr-checkbox .checkbox-group label {
  position: relative;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
  cursor: pointer;
  text-transform: capitalize;
}
.cr-checkbox .checkbox-group label::before {
  margin-right: 10px;
  margin-top: -4px;
  padding: 8px;
  content: "";
  background-color: transparent;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}
.cr-checkbox .checkbox-group span {
  font-family: "Poppins, sans-serif";
  font-size: 12px;
  color: #7a7a7a;
  position: absolute;
  right: 0;
}

.cr-shop-price {
  padding-top: 25px;
}
.cr-shop-price .price-range-slider {
  width: 100%;
  margin-top: 30px;
}
.cr-shop-price .price-range-slider .range-value {
  margin: 20px 0 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-shop-price .price-range-slider .range-value label {
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  color: #000;
}
.cr-shop-price .price-range-slider .range-value input {
  width: calc(100% - 50px);
  padding-left: 6px;
  background: none;
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  color: #7a7a7a;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  outline: none;
}
.cr-shop-price .price-range-slider .range-bar {
  height: 3px;
  width: 100%;
  margin-left: 8px;
  border: none;
  background-color: #e9e9e9;
}
.cr-shop-price .price-range-slider .range-bar .ui-slider-range {
  background-color: #64b496;
}
.cr-shop-price .price-range-slider .range-bar .ui-slider-handle {
  height: 14px;
  width: 14px;
  border: none;
  border-radius: 25px;
  background-color: #64b496;
  border: none;
  top: -0.3em;
  cursor: pointer;
}
.cr-shop-price .price-range-slider .range-bar .ui-slider-handle + span {
  background-color: #64b496;
}

.cr-shop-color {
  padding-top: 25px;
}
.cr-shop-color span {
  height: 20px;
  width: 20px;
  border-radius: 5px;
}
.cr-shop-color .blue {
  background-color: #6c9eff;
}
.cr-shop-color .yellow {
  background-color: #dede44;
}
.cr-shop-color .red {
  background-color: #fb5555;
}
.cr-shop-color .green {
  background-color: #64b496;
}

.cr-shop-weight {
  padding-top: 25px;
}

.cr-shop-tags {
  padding-top: 25px;
}
.cr-shop-tags .cr-shop-tags-inner {
  padding-top: 25px;
}

ul.cr-tags {
  margin: -5px;
  padding: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
ul.cr-tags li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 32px;
  padding: 0 15px;
  margin: 5px;
  border-radius: 5px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  background-color: #fff;
  color: #7a7a7a;
  border: 1px solid #e9e9e9;
  line-height: 30px;
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
}
ul.cr-tags li a:hover {
  color: #fff;
  background-color: #64b496;
  border: 1px solid #64b496;
}

.cr-product-details p.text {
  margin-bottom: 10px;
  display: none;
  text-align: left;
}
.cr-product-details ul.list {
  display: none;
}

/* Grid */
.col-100.col-size .cr-product-box {
  width: 100%;
}
.col-100.col-size .cr-product-box .cr-product-card {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-image {
  max-width: 300px;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-image .cr-shopping-bag {
  right: 12px;
  bottom: 12px;
  background-color: #fff;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-image .cr-shopping-bag.active {
  background-color: #64b496;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details {
  width: calc(100% - 300px);
  padding: 12px 12px 12px 24px;
  text-align: left;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details .cr-brand a {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details .cr-brand .cr-star {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details a.title {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details ul.list {
  padding: 0;
  display: block;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details ul.list li {
  padding: 5px 0;
  list-style: none;
  color: #777;
}
.col-100.col-size .cr-product-box .cr-product-card .cr-product-details ul.list li label {
  margin-right: 10px;
  color: #2b2b2d;
  font-weight: 500;
}

.col-50.col-size .cr-product-box {
  width: 50%;
}
.col-50.col-size .cr-product-box .cr-product-card {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-image {
  max-width: 300px;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-image .cr-shopping-bag {
  right: 12px;
  bottom: 12px;
  background-color: #fff;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-image .cr-shopping-bag.active {
  background-color: #64b496;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details {
  padding: 12px 12px 12px 24px;
  text-align: left;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details .cr-brand a {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details .cr-brand .cr-star {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details a.title {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details ul.list {
  padding: 0;
  display: block;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details ul.list li {
  padding: 5px 0;
  list-style: none;
  color: #777;
}
.col-50.col-size .cr-product-box .cr-product-card .cr-product-details ul.list li label {
  margin-right: 10px;
  color: #2b2b2d;
  font-weight: 500;
}

/* full Width */
.filter-sidebar-overlay {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.cr-shop-leftside {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: fixed;
  top: 0;
  left: -370px;
  overflow-x: auto;
  z-index: 21;
}
.cr-shop-leftside .cr-shop-leftside-inner {
  width: 350px;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #fff;
}
.cr-shop-leftside .cr-shop-leftside-inner .cr-shop-sideview {
  border-radius: 0px;
}
.cr-shop-leftside .cr-shop-leftside-inner .cr-title {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-shop-leftside .cr-shop-leftside-inner .cr-title h6 {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  color: #2b2b2d;
}
.cr-shop-leftside .cr-shop-leftside-inner .cr-title .close-shop-leftside {
  color: #fb5555;
}
.cr-shop-leftside .cr-shop-leftside-inner .cr-title .close-shop-leftside i {
  font-size: 22px;
}

.cr-shop-leftside-active {
  left: 0;
}

.active-grid {
  background-color: #64b496 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #e9e9e9 !important;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-shop-price .price-range-slider .range-value label {
    font-size: 14px;
  }
  .cr-shop-price .price-range-slider .range-value input {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-checkbox {
    padding-top: 30px;
  }
  h4.cr-shop-sub-title {
    font-size: 18px;
  }
  .col-50.col-size .cr-product-box {
    width: 100%;
  }
  .col-50.col-size .cr-product-box .cr-product-card .cr-product-image {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .cr-shop-bredekamp .center-content span {
    display: none;
  }
  .cr-shop-bredekamp .cr-select label {
    line-height: 2.2;
    font-size: 12px;
  }
  .cr-shop-bredekamp .cr-select select {
    font-size: 12px;
  }
  .col-100.col-size .cr-product-box .cr-product-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .col-100.col-size .cr-product-box .cr-product-card .cr-product-image {
    max-width: 100%;
  }
  .col-100.col-size .cr-product-box .cr-product-card .cr-product-details {
    width: 100%;
    padding: 24px 0 0 0;
  }
  .col-50.col-size .cr-product-box {
    width: 100%;
  }
  .col-50.col-size .cr-product-box .cr-product-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .col-50.col-size .cr-product-box .cr-product-card .cr-product-image {
    max-width: 100%;
  }
  .col-50.col-size .cr-product-box .cr-product-card .cr-product-details {
    width: 100%;
    padding: 24px 0 0 0;
  }
}
@media only screen and (max-width: 575px) {
  .cr-shop-bredekamp .center-content span {
    display: none;
  }
  .cr-shop-leftside .cr-shop-leftside-inner {
    width: 300px;
  }
}
@media only screen and (max-width: 420px) {
  .cr-shop-leftside .cr-shop-leftside-inner {
    width: 250px;
  }
}
@media only screen and (max-width: 360px) {
  .cr-shop-bredekamp .cr-select {
    padding: 3px 0px 3px 10px;
  }
  .cr-shop-bredekamp .cr-toggle .shop_side_view {
    margin-right: 7px;
  }
  .cr-shop-bredekamp .cr-toggle .gridCol {
    margin-right: 7px;
  }
}
/* Blog-classic */
.cr-blog-classic {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-blog-classic:not(:last-child) {
  margin-bottom: 24px;
}
.cr-blog-classic .cr-blog-classic-content {
  padding: 24px;
  text-align: left;
}
.cr-blog-classic .cr-blog-classic-content .cr-comment {
  padding-bottom: 10px;
}
.cr-blog-classic .cr-blog-classic-content .cr-comment span {
  font-family: "Poppins, sans-serif";
  font-size: 12px;
  color: #64b496;
  line-height: 1;
}
.cr-blog-classic .cr-blog-classic-content .cr-comment span code {
  font-family: "Poppins, sans-serif";
  font-size: 12px;
  color: #7a7a7a;
}
.cr-blog-classic .cr-blog-classic-content h4 {
  margin-bottom: 0px;
  padding-bottom: 15px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  color: #2b2b2d;
}
.cr-blog-classic .cr-blog-classic-content p {
  padding-bottom: 10px;
  font-size: 14px;
}
.cr-blog-classic .cr-blog-classic-content a {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.875;
  text-transform: uppercase;
  color: #64b496;
  position: relative;
}
.cr-blog-classic .cr-blog-classic-content a::after {
  font-family: remixicon;
  content: "\ea6c";
  font-size: 16px;
  line-height: 1.2;
  position: absolute;
  top: 0;
  right: -20px;
}
.cr-blog-classic .cr-blog-image {
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.cr-blog-classic .cr-blog-image img {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.cr-blog-classic .cr-blog-image img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cr-blog-sideview {
  padding: 24px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: sticky;
  top: 30px;
}
.cr-blog-sideview .cr-serch-box {
  padding-bottom: 25px;
}
.cr-blog-sideview .cr-serch-box .cr-search {
  position: relative;
}
.cr-blog-sideview .cr-serch-box .cr-search input {
  width: 100%;
  height: 44px;
  padding-left: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  outline: none;
}
.cr-blog-sideview .cr-serch-box .cr-search input::-webkit-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-blog-sideview .cr-serch-box .cr-search input::-moz-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-blog-sideview .cr-serch-box .cr-search input:-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-blog-sideview .cr-serch-box .cr-search input::-ms-input-placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-blog-sideview .cr-serch-box .cr-search input::placeholder {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
}
.cr-blog-sideview .cr-serch-box .cr-search input:focus-visible {
  border: 1px solid #e9e9e9;
}
.cr-blog-sideview .cr-serch-box .cr-search .search-btn {
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #64b496;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.cr-blog-sideview .cr-serch-box .cr-search .search-btn i {
  font-size: 14px;
  color: #fff;
}
.cr-blog-sideview .blog-heading {
  padding-bottom: 20px;
}
.cr-blog-sideview .blog-heading h4 {
  margin-bottom: 0;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #2b2b2d;
}

.cr-blog-categories-content {
  padding-bottom: 30px;
}
.cr-blog-categories-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.cr-blog-categories-content ul li {
  padding: 12px;
  position: relative;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  line-height: 1;
  color: #7a7a7a;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-blog-categories-content ul li:not(:last-child) {
  margin-bottom: 5px;
}
.cr-blog-categories-content ul li span {
  position: absolute;
  right: 12px;
  font-size: 11px;
  font-family: "Poppins, sans-serif";
  color: #7a7a7a;
  line-height: 1;
}

.cr-blog-recent-post {
  margin-bottom: 30px;
  padding: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-blog-recent-post .cr-blog-recent-image {
  position: relative;
}
.cr-blog-recent-post .cr-blog-recent-image img {
  width: 100%;
  border-radius: 5px;
}
.cr-blog-recent-post .cr-blog-recent-content {
  margin-top: 12px;
  text-align: center;
}
.cr-blog-recent-post .cr-blog-recent-content span {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  color: #64b496;
  line-height: 2.5;
}
.cr-blog-recent-post .cr-blog-recent-content h4 {
  margin-bottom: 0px;
  padding-bottom: 5px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: bold;
  color: #2b2b2d;
}
.cr-blog-recent-post .cr-blog-recent-content p {
  font-size: 13px;
}

.cr-blog-instagram-image {
  margin-bottom: 30px;
}
.cr-blog-instagram-image .cr-blog-instagram-image-inner {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(33% - 10px));
  grid-row-gap: 12px;
  grid-column-gap: 12px;
}
.cr-blog-instagram-image .cr-blog-instagram-image-inner img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

/* Responsive */
@media only screen and (max-width: 1199px) {
  .cr-blog-categories-content ul li {
    font-size: 12px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input {
    padding-left: 8px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input::-moz-placeholder {
    font-size: 12px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input:-ms-input-placeholder {
    font-size: 12px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input::-ms-input-placeholder {
    font-size: 12px;
  }
  .cr-blog-sideview .cr-serch-box .cr-search input::placeholder {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-blog-categories-content ul li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-blog-classic .cr-blog-classic-content {
    padding: 30px;
  }
  .cr-blog-classic .cr-blog-classic-content h4 {
    font-size: 18px;
  }
  .cr-blog-classic .cr-blog-classic-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 360px) {
  .cr-blog-classic .cr-blog-classic-content {
    padding: 5px;
  }
}
/* Blog-details */
.cr-blog-details .cr-blog-details-image {
  position: relative;
  margin-bottom: 30px;
}
.cr-blog-details .cr-blog-details-image img {
  width: 100%;
  border-radius: 5px;
}
.cr-blog-details .cr-blog-details-content .cr-admin-date {
  padding-bottom: 15px;
}
.cr-blog-details .cr-blog-details-content .cr-admin-date span {
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  color: #7a7a7a;
}
.cr-blog-details .cr-blog-details-content .cr-admin-date span code {
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  color: #64b496;
}
.cr-blog-details .cr-blog-details-content .cr-banner h2 {
  text-align: left;
}
.cr-blog-details .cr-blog-details-content p {
  font-size: 14px;
}

.cr-tags.blog {
  margin: 0;
}

.cr-blog-inner-cols .blog-img {
  position: relative;
}
.cr-blog-inner-cols .blog-img img {
  width: 100%;
  border-radius: 5px;
}
.cr-blog-inner-cols .cr-blog-inner-content {
  margin-top: 15px;
  position: relative;
}
.cr-blog-inner-cols .cr-blog-inner-content::after {
  font-family: remixicon;
  content: "\ea67";
  font-size: 24px;
  line-height: 1.2;
  color: #64b496;
  position: absolute;
  top: 0;
  left: 0;
}
.cr-blog-inner-cols .cr-blog-inner-content p {
  padding-left: 35px;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8;
  color: #2b2b2d;
}

.cr-blog-details-message {
  margin-top: 30px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: relative;
}
.cr-blog-details-message::after {
  font-family: remixicon;
  content: "\ef46";
  font-size: 60px;
  line-height: 1.2;
  color: #64b496;
  position: absolute;
  opacity: 0.5;
  bottom: 10px;
  right: 10px;
  line-height: 46px;
}
.cr-blog-details-message p {
  padding-bottom: 15px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  color: #2b2b2d;
}
.cr-blog-details-message h5.title {
  margin-bottom: 0px;
  font-family: "Poppins, sans-serif";
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #64b496;
}

.cr-blog-details-paragrap {
  margin-top: 30px;
}
.cr-blog-details-paragrap p {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #7a7a7a;
  line-height: 1.867;
}

.cr-blog-details-tags {
  margin-top: 20px;
}
.cr-blog-details-tags .cr-details-tags {
  padding: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  background: #fff;
}
.cr-blog-details-tags .cr-details-tags .tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-blog-details-tags .cr-details-tags span {
  margin-right: 10px;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  color: #2b2b2d;
}
.cr-blog-details-tags .cr-details-tags .cr-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.cr-blog-details-tags .cr-details-tags .cr-logo a {
  width: 32px;
  height: 32px;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

/* Responsive */
@media only screen and (max-width: 767px) {
  .cr-blog-details-tags .cr-tegs ul li {
    padding: 8px;
  }
  .cr-blog-details-message::after {
    font-size: 40px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-blog-details-tags .cr-tegs ul li {
    padding: 8px 5px;
    font-size: 12px;
  }
  .cr-blog-inner-cols .cr-blog-inner-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .cr-blog-inner-cols .cr-blog-inner-content::after {
    display: none;
  }
  .cr-blog-inner-cols .cr-blog-inner-content p {
    padding-left: 0;
  }
  .cr-blog-details-tags .cr-tegs span {
    display: none;
  }
  .cr-blog-details-tags .cr-tegs ul {
    margin: 0;
  }
  .cr-blog-inner-cols .cr-blog-inner-content p {
    font-size: 12px;
  }
  .cr-blog-details-message p {
    font-size: 12px;
  }
  .cr-blog-details-message::after {
    right: 20px;
  }
  .cr-blog-details-tags .cr-tegs .cr-logo {
    display: none;
  }
}
/* Faq */
.cr-faq .accordion .accordion-item {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-faq .accordion .accordion-item:not(:nth-child(1)) {
  margin-top: 10px;
}
.cr-faq .accordion .accordion-item .accordion-button {
  padding: 15px;
  background-color: transparent;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  color: #000;
}
.cr-faq .accordion .accordion-item .accordion-button::after {
  background: none;
  font-family: remixicon;
  content: "\ea6e";
  font-size: 22px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  line-height: 21px;
}
.cr-faq .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background: none;
  font-family: remixicon;
  content: "\ea6e";
  font-size: 22px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.cr-faq .accordion .accordion-item .accordion-collapse {
  border-top: 1px solid #e9e9e9;
}
.cr-faq .accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 15px;
  position: relative;
}
.cr-faq .accordion .accordion-item .accordion-collapse .accordion-body p {
  font-size: 14px;
}

.cr-faq-img img {
  width: 100%;
  height: 100%;
  min-height: 380px;
  border-radius: 5px;
}

/**  Responsive  **/
@media only screen and (max-width: 991px) {
  .cr-faq-img {
    margin-bottom: 30px;
  }
}
/* About */
.cr-about {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cr-about h4.heading {
  margin-bottom: 16px;
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}
.cr-about .elementor-text-editor p {
  font-size: 14px;
}

.cr-about-image {
  position: sticky;
  top: 0;
}
.cr-about-image img {
  width: 100%;
  border-radius: 5px;
}

.cr-about-content {
  padding-top: 5px;
}
.cr-about-content p {
  font-size: 14px;
}
.cr-about-content p:not(:last-child) {
  margin-bottom: 24px;
}
.cr-about-content .elementor-counter {
  margin-top: 24px;
  padding: 24px;
  background-color: #f7f7f8;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.cr-about-content h4.elementor {
  font-family: "Manrope", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  color: #7a7a7a;
  text-align: center;
  letter-spacing: 0px;
}
.cr-about-content .elementor-counter-number {
  color: #64b496;
}
.cr-about-content .elementor-suffix {
  margin-left: -12px;
  font-size: 30px;
}
.cr-about-content .elementor-counter-title {
  text-align: center;
}
.cr-about-content .elementor-counter-title span {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

/* Responsive */
@media only screen and (max-width: 1199px) {
  .cr-about-content .elementor-suffix {
    margin-left: -5px;
  }
  .cr-about-content h4.elementor {
    font-size: 45px;
  }
  .cr-about-content .elementor-counter-title span {
    font-size: 15px;
  }
  .cr-about h4.heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 991px) {
  .cr-about-image {
    margin-top: 30px;
  }
  .cr-about-content p {
    display: block;
  }
  .cr-about h4.heading {
    font-size: 25px;
    line-height: 35px;
  }
  .cr-about-content h4.elementor {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-about h4.heading {
    font-size: 22px;
    line-height: 32px;
  }
  .cr-about-content h4.elementor {
    font-size: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .margin-575:not(:last-child) {
    margin-bottom: 30px;
  }
}
/* contact-us */
.cr-info-box {
  padding: 24px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  text-align: center;
}
.cr-info-box .cr-icon i {
  font-size: 30px;
  color: #64b496;
}
.cr-info-box .cr-info-content h4.heading {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #2b2b2d;
}
.cr-info-box .cr-info-content p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

iframe {
  width: 100%;
  height: 100%;
  margin-bottom: -7px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

.cr-content-form .form-group {
  margin-bottom: 24px;
}
.cr-content-form .form-group .cr-form-control {
  width: 100%;
  padding: 0.575rem 0.75rem;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  border: 1px solid #e9e9e9;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 5px;
}
.cr-content-form .form-group .cr-form-control::-webkit-input-placeholder {
  font-size: 14px;
  color: #777;
}
.cr-content-form .form-group .cr-form-control::-moz-placeholder {
  font-size: 14px;
  color: #777;
}
.cr-content-form .form-group .cr-form-control:-ms-input-placeholder {
  font-size: 14px;
  color: #777;
}
.cr-content-form .form-group .cr-form-control::-ms-input-placeholder {
  font-size: 14px;
  color: #777;
}
.cr-content-form .form-group .cr-form-control::placeholder {
  font-size: 14px;
  color: #777;
}

/* Responsive */
@media only screen and (max-width: 991px) {
  .cr-info-box .cr-info-content h4.heading {
    font-size: 22px;
  }
  .cr-info-box .cr-info-content p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-info-box {
    max-width: 350px;
    margin: auto;
  }
}
@media only screen and (max-width: 575px) {
  .cr-info-box {
    max-width: 300px;
    margin: auto;
  }
}
/* cart */
.cr-table-content table {
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  overflow: hidden;
}
.cr-table-content table thead tr {
  border: 1px solid #e9e9e9;
}
.cr-table-content table thead tr th {
  padding: 15px;
  border-top: medium none;
  color: #444;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;
  letter-spacing: 0;
  background-color: #e9e9e9;
}
.cr-table-content table tbody tr {
  border-bottom: 1px solid #e9e9e9;
}
.cr-table-content table tbody tr .cr-cart-name {
  width: 40%;
}
.cr-table-content table tbody tr .cr-cart-name a {
  color: #444;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  letter-spacing: 0.6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-table-content table tbody tr .cr-cart-price {
  color: #555;
  font-size: 15px;
  font-weight: 500;
}
.cr-table-content table tbody tr .cr-cart-subtotal {
  color: #555;
  font-weight: 500;
  font-size: 15px;
}
.cr-table-content table tbody tr .cr-cart-remove {
  width: 90px;
  text-align: right;
}
.cr-table-content table tbody tr .cr-cart-remove a {
  margin: 0 auto;
  color: #555;
}
.cr-table-content table tbody tr .cr-cart-remove a:hover {
  color: #fb5555;
}
.cr-table-content table tbody tr .cr-cart-remove a i {
  font-size: 22px;
}
.cr-table-content table tbody tr td {
  padding: 25px 14px;
  color: #444;
  font-size: 16px;
  text-align: left;
  background-color: #f7f7f8;
}
.cr-table-content table tbody tr td .cr-cart-img {
  width: 60px;
  margin-right: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

.cr-cart-qty {
  text-align: center;
}
.cr-cart-qty .cart-qty-plus-minus {
  width: 80px;
  height: 30px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-cart-qty .cart-qty-plus-minus input {
  width: 30px;
  margin: 0;
  padding: 0;
  background: transparent none repeat scroll 0 0;
  color: #444;
  border: medium none;
  float: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 38px;
  height: auto;
  text-align: center;
  outline: none;
}
.cr-cart-qty .cart-qty-plus-minus button {
  height: 25px;
  width: 25px;
  margin-top: -2px;
  border: none;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cr-cart-update-bottom {
  padding: 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cr-cart-update-bottom a.cr-links {
  color: #444;
  display: inline-block;
  text-decoration: underline;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

/* Responsive */
@media only screen and (max-width: 767px) {
  .cr-cart-content {
    overflow-x: scroll;
  }
  .cr-cart-content .row {
    width: 700px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-cart-view .cr-cart-inner {
    width: 300px;
  }
}
@media only screen and (max-width: 360px) {
  .cr-cart-view .cr-cart-inner {
    width: 280px;
  }
}
/* Login */
.cr-login {
  max-width: 400px;
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-login .cr-content-form .form-group label {
  margin-bottom: 9px;
  color: #444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}
.cr-login .cr-content-form .remember {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 15px;
}
.cr-login .cr-content-form .remember input {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.cr-login .cr-content-form .remember .link {
  color: #777;
}
.cr-login .cr-content-form .custom {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-login .cr-content-form .custom input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.cr-login .cr-content-form .custom input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 11px;
  border: solid #64b496;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.cr-login .cr-content-form .custom label {
  margin: 0;
  position: relative;
  cursor: pointer;
  color: #777;
  font-weight: 400;
}
.cr-login .cr-content-form .custom label:before {
  content: "";
  margin-right: 10px;
  padding: 8px;
  background-color: transparent;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
}
.cr-login .cr-content-form .login-buttons {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-login .cr-content-form .login-buttons button {
  margin-right: 15px;
  padding: 8px 20px;
}
.cr-login .cr-content-form .login-buttons .link {
  padding: 8px 0;
  color: #777;
}

.form-logo {
  width: 100%;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Responsive */
@media only screen and (max-width: 420px) {
  .cr-login .cr-content-form .remember {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .cr-login .cr-content-form .custom label {
    margin-bottom: 10px;
  }
}
/* Register */
.cr-register {
  max-width: 600px;
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-register form .form-group label {
  margin-bottom: 9px;
  color: #444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}
.cr-register form .form-group .cr-form-control {
  font-size: 14px;
  color: #777;
}

.cr-register-buttons {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-register-buttons button {
  margin-right: 15px;
  padding: 8px 20px;
}
.cr-register-buttons .link {
  padding: 8px 0;
  color: #777;
}

/* elemets-products */
.cr-product-csc {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  background-color: #fff;
  font-family: "Poppins, sans-serif";
  text-align: center;
  border: 1px solid #e9e9e9;
  overflow: hidden;
}
.cr-product-csc .m-15 {
  margin: 15px 15px 0 15px;
}
.cr-product-csc .cr-side-view {
  position: absolute;
  top: 12px;
  right: 12px;
}
.cr-product-csc .cr-side-view a {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
}
.cr-product-csc .cr-side-view a:not(:last-child) {
  margin-bottom: 5px;
}
.cr-product-csc .cr-product-image {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background-color: #f7f7f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.cr-product-csc .cr-product-image img {
  width: 150px;
}
.cr-product-csc .cr-product-image .cr-shopping-bag {
  bottom: 12px;
  right: 12px;
  background-color: #fff;
}
.cr-product-csc .cr-product-image .cr-product-base {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 45px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase !important;
  color: #fff;
  background-color: #373840;
  z-index: 10;
}
.cr-product-csc .cr-product-details {
  padding: 15px 15px 0 15px;
  position: relative;
  background-color: #fff;
}
.cr-product-csc .cr-product-details p {
  margin-bottom: 5px;
}
.cr-product-csc .cr-product-details p.cr-price {
  margin-bottom: 0;
}
.cr-product-csc .cr-product-details a {
  font-weight: 500;
}

/* elemets-typography */
.typography .cr-fw-normal {
  font-weight: 500;
}
.typography .cr-fw-bold {
  font-weight: 700;
}
.typography .cr-lb {
  padding-left: 10px;
  border-left: 5px solid;
}
.typography .cr-fc {
  color: #2b2b2d;
}
.typography .cr-rb {
  padding-right: 10px;
  border-right: 5px solid;
  text-align: right;
}
.typography h1 {
  font-size: 38px;
}
.typography .cr-disc, .typography .cr-decimal, .typography .cr-alpha, .typography .cr-roman, .typography .cr-greek, .typography .cr-hebrew {
  padding: 5px 20px;
  margin-bottom: 0;
  color: #777;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  letter-spacing: 1px;
  font-family: "Manrope", sans-serif;
}
.typography .cr-disc li {
  margin-bottom: 15px;
  list-style-type: disc;
}
.typography .cr-decimal li {
  margin-bottom: 15px;
  list-style-type: decimal;
}
.typography .cr-alpha li {
  list-style-type: upper-alpha;
}
.typography .cr-alpha li:not(:last-child) {
  margin-bottom: 15px;
}
.typography .cr-roman li {
  margin-bottom: 15px;
  list-style-type: upper-roman;
}
.typography .cr-greek li {
  list-style-type: lower-greek;
}
.typography .cr-greek li:not(:last-child) {
  margin-bottom: 15px;
}
.typography .cr-hebrew li {
  margin-bottom: 15px;
  list-style-type: hebrew;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5 {
  margin-bottom: 30px;
  font-family: "Montserrat";
}

/* elemets-buttons */
.cr-btn-ds {
  margin: -10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-btn-ds .btn {
  margin: 10px;
  border-radius: 5px;
}

.btn {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 45px;
  padding: 0;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 0;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 45px;
  text-transform: uppercase;
}

.btn-primary {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #fff;
  background-color: #3474d4;
  border-color: #3474d4;
  border: 0;
}

.btn-secondary {
  color: #fff;
  background-color: #555;
  border-color: #555;
}

.btn-success {
  color: #fff;
  background-color: #64b496;
  border-color: #64b496;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-link:hover {
  color: #fff;
}

.cr-btn-bw {
  margin: -10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  /* 1 */
  /* 2 */
  /* 3 */
  /* 4 */
  /* 5 */
  /* 6 */
  /* 7 */
  /* 8 */
  /* 9 */
  /* 10 */
  /* 11 */
  /* 12 */
  /* 13 */
  /* 14 */
  /* 15 */
  /* 16 */
}
.cr-btn-bw .custom-btn {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 150px;
  height: 40px;
  margin: 10px;
  padding: 10px 25px;
  outline: none;
  border: 2px solid #64b496;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: inline-block;
  line-height: 17px;
}
.cr-btn-bw .btn-1 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cr-btn-bw .btn-1:hover {
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.cr-btn-bw .btn-2::after {
  position: absolute;
  content: "";
  top: 4px;
  left: 5px;
  right: 5px;
  height: 28px;
  border: 1px solid #64b496;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cr-btn-bw .btn-2:hover::after {
  opacity: 1;
}
.cr-btn-bw .btn-3 {
  line-height: 39px;
  padding: 0;
}
.cr-btn-bw .btn-3:hover {
  background: transparent;
  color: #64b496;
  border: none;
}
.cr-btn-bw .btn-3:hover::before {
  height: 100%;
}
.cr-btn-bw .btn-3:hover::after {
  width: 100%;
}
.cr-btn-bw .btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.cr-btn-bw .btn-3 span::after,
.cr-btn-bw .btn-3 span ::before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background-color: #64b496;
}
.cr-btn-bw .btn-3 span::before {
  width: 2px;
  height: 0%;
}
.cr-btn-bw .btn-3 span::after {
  width: 0%;
  height: 2px;
}
.cr-btn-bw .btn-3 span:hover::before {
  height: 100%;
}
.cr-btn-bw .btn-3 span:hover::after {
  width: 100%;
}
.cr-btn-bw .btn-3::after,
.cr-btn-bw .btn-3 ::before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: #64b496;
}
.cr-btn-bw .btn-3::before {
  height: 0%;
  width: 2px;
}
.cr-btn-bw .btn-3::after {
  width: 0%;
  height: 2px;
}
.cr-btn-bw .btn-4 {
  padding: 0;
  position: relative;
  color: #64b496;
  z-index: 2;
}
.cr-btn-bw .btn-4:hover {
  border: none;
}
.cr-btn-bw .btn-4:hover::before,
.cr-btn-bw .btn-4:hover ::after {
  height: 100%;
  width: 100%;
  border-color: #64b496;
}
.cr-btn-bw .btn-4::before,
.cr-btn-bw .btn-4 ::after {
  width: 0%;
  height: 0%;
  position: absolute;
  content: "";
  border: 2px solid;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cr-btn-bw .btn-4::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #64b496;
  border-left-color: #64b496;
}
.cr-btn-bw .btn-4::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #64b496;
  border-right-color: #64b496;
}
.cr-btn-bw .btn-5 {
  padding: 0;
  background-color: #64b496;
  color: #fff;
  line-height: 38px;
  border: none;
}
.cr-btn-bw .btn-5:hover {
  background: transparent;
  color: #64b496;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.cr-btn-bw .btn-5:hover::after, .cr-btn-bw .btn-5:hover::before {
  -webkit-transition: 800ms ease all;
  transition: 800ms ease all;
  width: 100%;
}
.cr-btn-bw .btn-5::after, .cr-btn-bw .btn-5::before {
  -webkit-transition: 400ms ease all;
  transition: 400ms ease all;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background-color: #64b496;
}
.cr-btn-bw .btn-5::after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}
.cr-btn-bw .btn-6 {
  background-color: #64b496;
  color: #fff;
  line-height: 38px;
  padding: 0;
  border: none;
}
.cr-btn-bw .btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.cr-btn-bw .btn-6 span::after,
.cr-btn-bw .btn-6 span ::before {
  position: absolute;
  content: "";
  background-color: #64b496;
}
.cr-btn-bw .btn-6 span::before {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 0%;
  height: 2px;
  left: 0;
  top: 0;
}
.cr-btn-bw .btn-6 span::after {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 0%;
  height: 2px;
  right: 0;
  bottom: 0;
}
.cr-btn-bw .btn-6 span:hover::before {
  width: 100%;
}
.cr-btn-bw .btn-6 span:hover::after {
  width: 100%;
}
.cr-btn-bw .btn-6::after,
.cr-btn-bw .btn-6 ::before {
  height: 0%;
  width: 2px;
  position: absolute;
  content: "";
  background-color: #64b496;
}
.cr-btn-bw .btn-6::before {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  right: 0;
  top: 0;
}
.cr-btn-bw .btn-6::after {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  left: 0;
  bottom: 0;
}
.cr-btn-bw .btn-6:hover {
  background-color: transparent;
  color: #64b496;
}
.cr-btn-bw .btn-6:hover::before {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
}
.cr-btn-bw .btn-6:hover::after {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
}
.cr-btn-bw .btn-7 {
  padding: 0;
  background-color: #64b496;
  color: #fff;
  line-height: 38px;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cr-btn-bw .btn-7:hover {
  background-color: transparent;
  color: #64b496;
}
.cr-btn-bw .btn-7:hover::after,
.cr-btn-bw .btn-7:hover ::before {
  height: 0;
  background-color: #64b496;
}
.cr-btn-bw .btn-7::after,
.cr-btn-bw .btn-7 ::before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #64b496;
}
.cr-btn-bw .btn-7::before {
  top: 0;
}
.cr-btn-bw .btn-7::after {
  bottom: 0;
}
.cr-btn-bw .btn-8 {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  padding: 0;
  line-height: 40px;
  background: transparent;
  position: relative;
  z-index: 2;
  color: #fff;
  -webkit-perspective: 300px;
          perspective: 300px;
}
.cr-btn-bw .btn-8:hover {
  color: #64b496;
}
.cr-btn-bw .btn-8:after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: #64b496;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
}
.cr-btn-bw .btn-8:hover::after {
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);
}
.cr-btn-bw .btn-9 {
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}
.cr-btn-bw .btn-9::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
}
.cr-btn-bw .btn-9:hover {
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
          box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  color: #fff;
}
.cr-btn-bw .btn-9:hover::after {
  -webkit-transform: scale(2) rotate(180deg);
          transform: scale(2) rotate(180deg);
  background-color: #64b496;
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
          box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}
.cr-btn-bw .btn-10 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}
.cr-btn-bw .btn-10::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
}
.cr-btn-bw .btn-10:hover {
  color: #fff;
}
.cr-btn-bw .btn-10:hover::after {
  background-color: #64b496;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.cr-btn-bw .btn-11 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}
.cr-btn-bw .btn-11:hover {
  background-color: #64b496;
  color: #fff;
}
.cr-btn-bw .btn-11::before {
  width: 30px;
  height: 100%;
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  background-color: #fff;
  -webkit-animation: shiny-btn1 3s ease-in-out infinite;
          animation: shiny-btn1 3s ease-in-out infinite;
}
.cr-btn-bw .btn-11:active {
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
          box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}
.cr-btn-bw .btn-12 {
  width: 130px;
  height: 40px;
  position: relative;
  border: none;
  line-height: 34px;
  -webkit-perspective: 230px;
          perspective: 230px;
  overflow: hidden;
}
.cr-btn-bw .btn-12 span {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 130px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #64b496;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.cr-btn-bw .btn-12 span:nth-child(1) {
  -webkit-box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.6), -4px -4px 5px 0px rgba(255, 255, 255, 0.6), 7px 7px 20px 0px #000, 4px 4px 5px 0px #000;
          box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.6), -4px -4px 5px 0px rgba(255, 255, 255, 0.6), 7px 7px 20px 0px #000, 4px 4px 5px 0px #000;
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -24px;
          transform-origin: 50% 50% -24px;
}
.cr-btn-bw .btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
          transform-origin: 50% 50% -20px;
}
.cr-btn-bw .btn-12:hover span:nth-child(1) {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}
.cr-btn-bw .btn-12:hover span:nth-child(2) {
  background-color: #e0e5ec;
  color: #e0e5ec;
  -webkit-transform: rotateX(-100deg);
          transform: rotateX(-100deg);
}
.cr-btn-bw .btn-13 {
  background-color: #64b496;
  color: #fff;
  z-index: 1;
}
.cr-btn-bw .btn-13::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 0;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #e0e5ec;
}
.cr-btn-bw .btn-13:hover {
  color: #64b496;
}
.cr-btn-bw .btn-13:hover::after {
  height: 100%;
  top: 0;
}
.cr-btn-bw .btn-13:active {
  top: 2px;
}
.cr-btn-bw .btn-14 {
  background-color: #64b496;
  color: #fff;
  z-index: 1;
}
.cr-btn-bw .btn-14::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  height: 0;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #e0e5ec;
}
.cr-btn-bw .btn-14:active {
  top: 2px;
}
.cr-btn-bw .btn-14:hover {
  color: #64b496;
}
.cr-btn-bw .btn-14:hover::after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.cr-btn-bw .btn-15 {
  background-color: #64b496;
  color: #fff;
  z-index: 1;
}
.cr-btn-bw .btn-15::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #e0e5ec;
}
.cr-btn-bw .btn-15:active {
  top: 2px;
}
.cr-btn-bw .btn-15:hover {
  color: #64b496;
}
.cr-btn-bw .btn-15:hover::after {
  width: 100%;
  left: 0;
}
.cr-btn-bw .btn-16 {
  background-color: #64b496;
  color: #fff;
  z-index: 1;
}
.cr-btn-bw .btn-16::after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background-color: #e0e5ec;
}
.cr-btn-bw .btn-16:active {
  top: 2px;
}
.cr-btn-bw .btn-16:hover {
  color: #64b496;
}
.cr-btn-bw .btn-16:hover::after {
  left: auto;
  right: 0;
  width: 100%;
}

/* Wishlist */
.cr-wishlist-msg {
  padding: 30px 0;
  text-align: center;
  font-size: 50px;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #999;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .cr-wishlist-msg {
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .cr-wishlist-msg {
    font-size: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-wishlist-msg {
    font-size: 18px;
  }
}
/*-------------------------------------------------
  Track Order Page CSS
---------------------------------------------------*/
.cr-track .cr-track-box {
  border-radius: 5px;
}
.cr-track .cr-track-card {
  height: 100%;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  color: #777;
  text-align: center;
  font-size: 15px;
  background-color: #f7f7f8;
}
.cr-track .cr-track-card .cr-track-title {
  margin-bottom: 5px;
  color: #2b2b2d;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}
.cr-track .cr-steps {
  margin-top: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.cr-track .cr-steps-body {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.cr-track .cr-step {
  padding: 30px 15px;
  display: table-cell;
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border-right: 1px solid #e9e9e9;
  color: #777;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  background-color: #fff;
}
.cr-track .cr-step:last-child {
  border-right: 0;
}
.cr-track .cr-step:hover {
  color: #2b2b2d;
  text-decoration: none;
}
.cr-track .cr-step:hover .cr-step-indicator {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border-color: transparent;
  background-color: #e9e9e9;
}
.cr-track .cr-step:hover .cr-step-icon {
  color: #777;
}
.cr-track .cr-step.cr-step-completed {
  background-color: #f7f7f8;
}
.cr-track .cr-step-indicator {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  background-color: #fff;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.cr-track .has-indicator {
  padding-right: 1.5rem;
  padding-left: 2.375rem;
}
.cr-track .has-indicator .cr-step-indicator {
  top: 50%;
  margin-top: -0.75rem;
}
.cr-track .cr-step-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  margin-bottom: 0.75rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #999;
  font-size: 22px;
}
.cr-track .cr-step-active {
  color: #2b2b2d;
  pointer-events: none;
  cursor: default;
  font-weight: 500;
}
.cr-track .cr-step-active:hover {
  color: #2b2b2d;
  pointer-events: none;
  cursor: default;
}
.cr-track .cr-step-active:hover .cr-step-icon {
  color: #64b496;
}
.cr-track .cr-step-active .cr-step-icon {
  color: #64b496;
}
.cr-track .cr-step-completed .cr-step-indicator {
  border-color: transparent;
  background-color: #64b496;
  color: #fff;
  line-height: 1.25rem;
}
.cr-track .cr-step-completed .cr-step-indicator i {
  line-height: 23px;
}
.cr-track .cr-step-completed:hover .cr-step-indicator {
  border-color: transparent;
  background-color: #64b496;
  color: #fff;
  line-height: 1.25rem;
}
.cr-track .cr-step-completed:hover .cr-step-indicator i {
  line-height: 23px;
}

/* Responsive */
@media only screen and (max-width: 767px) {
  .cr-track-box .row > div:not(:last-child) {
    margin-bottom: 24px !important;
  }
  .cr-track .cr-steps-body {
    display: -ms-grid;
    display: grid;
  }
  .cr-track .cr-step {
    border-right: 0;
  }
  .cr-track .cr-step:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
  }
}
/*-------------------------------------------------
  Checkout Page CSS
---------------------------------------------------*/
.cr-checkout-section {
  font-size: 14px;
}
.cr-checkout-section label {
  margin-bottom: 15px;
}
.cr-checkout-section [type=radio]:checked {
  position: relative;
  opacity: 0;
  display: none;
}
.cr-checkout-section [type=radio]:checked + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #7a7a7a;
  letter-spacing: 0;
}
.cr-checkout-section [type=radio]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #64b496;
}
.cr-checkout-section [type=radio]:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #64b496;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.cr-checkout-section [type=radio]:not(:checked) {
  position: relative;
  opacity: 0;
  display: none;
}
.cr-checkout-section [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #7a7a7a;
  letter-spacing: 0;
}
.cr-checkout-section [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #ffffff;
}
.cr-checkout-section [type=radio]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #64b496;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cr-checkout-wrap {
  padding: 24px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  border-radius: 5px;
}
.cr-checkout-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 25px;
  position: relative;
  display: block;
  color: #2b2b2d;
  font-family: "Montserrat";
  line-height: 1;
}
.cr-checkout-wrap .cr-check-login h3 {
  margin-bottom: 22px;
}
.cr-checkout-wrap .cr-check-bill h3 {
  margin-bottom: 26px;
  line-height: 1.2;
}
.cr-checkout-wrap .cr-check-bill .cr-check-subtitle {
  margin-bottom: 10px;
}

.cr-sidebar-wrap {
  border: 1px solid #e9e9e9;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
}
.cr-sidebar-wrap:last-child {
  margin-bottom: 0;
}
.cr-sidebar-wrap .cr-sb-block-content {
  margin-bottom: 0;
  border-bottom: none;
}
.cr-sidebar-wrap .cr-sb-title h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
  position: relative;
  color: #000;
}
.cr-sidebar-wrap .cr-sb-title h3 .cr-sidebar-res {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.cr-sb-block-content {
  margin-top: 15px;
}

.cr-checkout-pay-wrap [type=radio]:checked + label {
  margin-bottom: 0;
}
.cr-checkout-pay-wrap [type=radio]:not(:checked) + label {
  margin-bottom: 0;
}

.payment-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.payment-options .cr-pay-option:not(:last-child) {
  margin-bottom: 10px;
}

.cr-new-product {
  margin-top: 2px;
}

.cr-check-subtitle {
  margin-bottom: 14px;
  color: #2b2b2d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.cr-checkout-block.cr-check-new {
  margin-bottom: 30px;
}

.cr-new-option span {
  margin-right: 45px;
}

.cr-new-desc {
  position: relative;
  line-height: 24px;
  color: #7a7a7a;
  letter-spacing: 0;
  max-width: 64%;
}

.cr-new-btn {
  margin-top: 26px;
}
.cr-new-btn a {
  height: 40px;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 150px;
  line-height: 40px;
  letter-spacing: 0;
}

.cr-check-login-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cr-check-login-form label {
  margin-bottom: 11px;
  color: #2b2b2d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}
.cr-check-login-form input {
  height: 50px;
  background-color: transparent;
  border: 1px solid #e9e9e9;
  color: #2b2b2d;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 0 15px;
  width: 100%;
  outline: none;
  border-radius: 5px;
}
.cr-check-login-form .cr-check-login-fp {
  color: #2b2b2d;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-decoration: underline;
  font-size: 15px;
  line-height: 24px;
}
.cr-check-login-form .cr-check-login-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 14px;
}
.cr-check-login-form .cr-check-login-btn .cr-btn-2 {
  margin-right: 20px;
}

.cr-bill-option {
  margin-bottom: 24px;
  display: block;
}
.cr-bill-option span {
  margin-right: 45px;
}

.padding-bottom-3 {
  padding-bottom: 3px !important;
}

/*-----  Checkout Page CSS  ------*/
.cr-checkout-wrap .cr-check-bill-form .cr-bill-wrap {
  width: 100%;
  padding: 0 15px;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bill-wrap.cr-bill-half {
  width: 50%;
}
.cr-checkout-wrap .cr-check-bill-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin: 0 -15px;
}
.cr-checkout-wrap .cr-check-bill-form label {
  margin-bottom: 7px;
  color: #2b2b2d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}
.cr-checkout-wrap .cr-check-bill-form input {
  height: 50px;
  background-color: transparent;
  border: 1px solid #e9e9e9;
  color: #2b2b2d;
  font-size: 14px;
  margin-bottom: 26px;
  padding: 0 15px;
  width: 100%;
  outline: none;
  border-radius: 5px;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bill-fp label {
  margin-bottom: 0;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bill-fp label a {
  color: #2b2b2d;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bl-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  font-size: 14px;
  padding: 0;
  margin-bottom: 24px;
  border-radius: 5px;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bl-select-inner select {
  padding: 0 15px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 10px;
  color: #7a7a7a;
  cursor: pointer;
  border-radius: 5px;
}
.cr-checkout-wrap .cr-check-bill-form .cr-bl-select-inner::after {
  content: "\ea4e";
  position: relative;
  font-family: "remixicon";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #7a7a7a;
}

.cr-checkout-content .cr-check-order-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0;
}
.cr-checkout-content .cr-check-order-btn .btn {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0 5px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0;
}

.cr-checkout-summary > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.cr-checkout-summary .text-left {
  color: #7a7a7a;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}
.cr-checkout-summary .text-right {
  color: #000;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
}
.cr-checkout-summary .text-right a {
  color: #64b496;
  font-weight: 500;
  font-size: 14px;
}
.cr-checkout-summary .cr-checkout-summary-total {
  border-top: 1px solid #e9e9e9;
  padding-top: 19px;
  margin-bottom: 0;
  margin-top: 16px;
}
.cr-checkout-summary .cr-checkout-summary-total .text-left {
  font-size: 16px;
  font-weight: 600;
  color: #2b2b2d;
  font-family: "Manrope", sans-serif;
}
.cr-checkout-summary .cr-checkout-summary-total .text-right {
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}

/*-----  Sidebar Page CSS  ------*/
.cr-sidebar-wrap .cr-sidebar-block .cr-checkout-pro {
  margin-top: 34px;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-checkout-pro > div:not(:last-child) {
  margin-bottom: 15px;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro li {
  padding: 1px;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro li a {
  margin: 0 auto;
  display: block;
  color: #7a7a7a;
  font-size: 12px;
  margin-top: 0;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  cursor: pointer;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-pro-size li a {
  pointer-events: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-color: #e9e9e9;
  color: #7a7a7a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  line-height: 18px;
  padding-top: 2px;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-pro-size li.active a {
  background-color: #000;
  color: #fff;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content {
  width: calc(100% - 143px);
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 0;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content i {
  color: #f4a263;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content h5 {
  padding: 0 15px 0 0;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content h5 a {
  font-size: 15px;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content.cr-product-details {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content.cr-product-details h5 {
  text-align: left;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content.cr-product-details .cr-pro-rating {
  margin-bottom: 5px;
  text-align: left;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-content.cr-product-details .cr-price {
  text-align: left;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-image-outer {
  width: 80px;
  margin-right: 15px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-image-outer .cr-pro-image {
  overflow: hidden;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-checkout-pro .cr-product-inner .cr-pro-image-outer .cr-pro-image img {
  max-width: 100%;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-del-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-del-option > * {
  width: 50%;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-del-opt-head {
  color: #2b2b2d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-del-desc {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
  display: block;
  letter-spacing: 0;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-pay-opt-head {
  color: #2b2b2d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 6px;
  display: block;
}
.cr-sidebar-wrap .cr-sidebar-block .cr-sb-block-content .cr-pay-desc {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 15px;
  display: block;
  letter-spacing: 0;
}

.cr-product-inner .cr-pro-content .cr-price:not(:last-child) {
  margin-bottom: 4px;
}

.cr-sidebar-wrap.cr-checkout-del-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}
.cr-sidebar-wrap.cr-checkout-del-wrap .cr-del-commemt {
  margin-top: 12px;
  display: block;
}
.cr-sidebar-wrap.cr-checkout-del-wrap .cr-del-commemt .cr-del-opt-head {
  margin-bottom: 8px;
}
.cr-sidebar-wrap.cr-checkout-del-wrap textarea {
  background: transparent;
  border: 1px solid #e9e9e9;
  color: #2b2b2d;
  height: 100px;
  padding: 10px 15px;
  margin-bottom: 0;
  width: 100%;
  outline: none;
  font-size: 14px;
  display: block;
  border-radius: 5px;
}

.cr-sidebar-wrap.cr-checkout-pay-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap textarea {
  background: transparent;
  border: 1px solid #e9e9e9;
  color: #2b2b2d;
  height: 110px;
  padding: 10px 15px;
  margin-bottom: 0;
  width: 100%;
  outline: none;
  font-size: 14px;
  display: block;
  border-radius: 5px;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-commemt .cr-pay-opt-head {
  margin-bottom: 8px;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree {
  position: relative;
  display: block;
  margin-top: 20px;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree input:checked ~ .checked::after {
  display: block;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree a {
  margin-left: 27px;
  display: block;
  color: #7a7a7a;
  font-size: 14px;
  margin-top: 0;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0;
  cursor: pointer;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree a span {
  font-weight: 500;
  color: #2b2b2d;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.cr-sidebar-wrap.cr-checkout-pay-wrap .cr-pay-agree .checked:after {
  left: -1px;
  top: -1px;
  width: 15px;
  height: 15px;
  border: 1px solid #64b496;
  content: "\eb7b";
  position: relative;
  display: none;
  font-family: "remixicon";
  font-size: 13px;
  color: #64b496;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
}

.cr-sidebar-wrap.cr-check-pay-img-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}

.cr-check-pay-img-wrap .cr-check-pay-img-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cr-check-pay-img-wrap .cr-check-pay-img img {
  width: 100%;
}

/*  Responsive  */
@media only screen and (max-width: 991px) {
  .cr-checkout-rightside {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-checkout-wrap .cr-check-bill-form .cr-bill-wrap.cr-bill-half {
    width: 100%;
  }
  .cr-checkout-wrap h3 {
    font-size: 18px;
  }
  .cr-new-desc {
    max-width: 100%;
  }
}
/* Terms & Conditions */
.cr-common-wrapper {
  padding: 24px 24px 0 24px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  background-color: #fff;
}
.cr-common-wrapper .cr-cgi-block {
  margin-bottom: 24px;
}
.cr-common-wrapper .cr-cgi-block h5 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}
.cr-common-wrapper .cr-cgi-block p {
  line-height: 28px;
}

/*  Responsive  */
@media only screen and (max-width: 991px) {
  .spacing-991 {
    margin-bottom: 24px;
  }
}
/*# sourceMappingURL=style.css.map */
.cr-footer ul li { 
  color: #fff !important;
}

.cr-footer ul li a{ 
  color: #fff !important;
}

.cr-last-footer p {
  color: #fff !important;
}
.bg-off-white {
  background-color: #3e3582 !important;
  border-color: #3e3582 !important;
  
}
.cr-footer h4.cr-sub-title { 
  color: #fac917 !important;
}

.top-header {
  border-bottom: none !important;
}

.cr-menu-list {
  margin: -15px 0px !important;
}

.cr-services {
  background-color:#3e3582;
}

.cr-services .cr-services-image i {
  color: #fff;
}

.cr-services .cr-services-contain h4 {
  color: #fac917;
}
.cr-services .cr-services-contain p {
  color: #fff;
}

.cr-button {
  height: 40px;
  transition: all 0.3s ease-in-out;
  padding: 8px 22px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.2;
  background-color: #3e3582;
  color: #fac917;
  border: 1px solid #3e3582;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cr-breadcrumb-image {
  width: 100%;
  height: 70px;
  background-color: #3e3582;
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.cr-breadcrumb-image .cr-breadcrumb-title h2 {
  margin-bottom: 0;
  font-family: "Manrope", sans-serif;
  font-size: 19px;
  line-height: 1;
  font-weight: bold;
  color: #fac917;
}


ul.navbar-nav li.nav-item:hover a.nav-link::before {
  width: 100%;
  background-color: #fac917;
}

.cr-breadcrumb-image .cr-breadcrumb-title span a {
  color: #fac917;
}
.cr-breadcrumb-image .cr-breadcrumb-title span {
  color: #fff;
}
.cr-info-box {
  padding: 24px;
  background-color: #3e3582;
  border: 1px solid #3e3582;
  border-radius: 5px;
  text-align: center;
}

.cr-info-box .cr-info-content h4.heading {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #fac917;
}

.cr-info-box .cr-icon i {
  font-size: 30px;
  color: #fff;
}

.cr-info-box a {
  text-decoration: none;
  color: #fff;
}

.cr-footer ul li::after {
  color: #fff;
}

.cr-categories ul.nav-tabs li.nav-item {
  background-color: #3e3582;
  border: 1px solid #3e3582;
}
.center-categories-inner span {
  color: #fff;
}
.cr-categories ul.nav-tabs li.nav-item .nav-link {
  color: #fac917 !important;
}
.cr-view-more {
  color: #fac917 !important;
}
.cr-menu-list .cr-category-icon-block {
  visibility: hidden;
}
.top-header .cr-logo img {
  width: 115px;
  height: 100px;
  position: relative;
  top: 40px;
}
.cr-side-categories:after {
  transition: all 0.3s ease-in-out;
  width: 200px;
  height: 200px;
  content: "";
  background-color: #3e3582;
  border-radius: 50%;
  position: absolute;
  top: -80px;
  left: -40px;
  z-index: 0;
}
.cr-product-tabs ul .active {
  color: #fac917 !important;
}
.cr-product-tabs ul li {
  background-color: #3e3582;
  color: #fff;
}

.cr-content-form .form-group .cr-form-control {
  border: 1px solid #3e3582;
 }
 .cr-shop-bredekamp {
  background-color: #3e3582;
  border: 1px solid #3e3582;
}
.cr-shop-bredekamp .cr-select {
  display: none;
}
.active-grid {
  background-color: #fac917 !important;
  color: #3e3582 !important;
  border-radius: 5px !important;
  border: 1px solid #e9e9e9 !important;
}
.cr-shop-bredekamp .center-content span {
  color: #fff;
}

ul.navbar-nav li.nav-item a.nav-link {
    color: #fff !important;
    font-family: "Poppins, sans-serif";
    font-size: 14px;
    font-weight: 500;
    padding: 11px 8px;
    position: relative;
    z-index: 1;
}

/* header {
  background-color: #3e3582;
  border-bottom: 1px solid #fac917;
  height: 142px;
}

.top-header .cr-right-bar .cr-right-bar-item span {
  color: #fff;
}

.ri-heart-3-line:before {
  content: "\ee0b";
  color: #fff;
} */
#cr-main-menu-desk {
  background-color: #3e3582;
}
.ri-shopping-cart-line:before {
  content: "\f120";
  color: #fff;
}

.cr-menu-list .cr-calling a {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}


.ri-phone-line:before {
  content: "\efec";
  color: #fff;
}

.cr-last-footer p a {
  color: #fac917 !important;
}
.error {
  border: 1px solid red !important;
}

.cr-left-side-contain {
  max-width: 550px;
  height: 80vh;
  padding: 30px;
  background: #3e3582;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.cr-slider .swiper-wrapper .swiper-slide-active h1 {
  animation: fadeInUp 2s both 1.5s;
  color: #fac917 !important;
}
.cr-left-side-contain h5 {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Poppins, sans-serif";
  font-weight: 700;
  color: #ccc;
}
.cr-left-side-contain p {
  font-size: 15px;
  color: #ccc;
}
.cr-button {
  background-color: #000;
  border-color: #000;
}
.cr-left-side-contain {
  max-width: 550px;
  height: 80vh;
  padding: 30px;
  background: #3e3582;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.7;
}
.cr-slider .swiper-wrapper .swiper-slide-active h1 {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}
.cr-banner h2 {
  font-family: "Roboto Slab", serif;
  font-size: 32px;
  font-weight: bold;
  color: #2b2b2d;
}
.cr-about h4.heading {
  margin-bottom: 16px;
  font-family: "Roboto Slab", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}
.cr-breadcrumb-image .cr-breadcrumb-title h2 {
  margin-bottom: 0;
  font-family: "Roboto Slab", serif;
  font-size: 19px;
  line-height: 1;
  font-weight: bold;
  color: #fac917;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", serif;
}
.cr-footer h4.cr-sub-title {
  font-family: "Roboto Slab", serif;
}
.cr-about-content .elementor-counter {
  margin-top: 24px;
  padding: 24px;
  background-color: #3e3582;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.cr-about-content .elementor-counter-number {
  color: #fac917;
}
.cr-about-content h4.elementor {
  color: #ccc;
}
.cr-about-content .elementor-counter-title span {
  color: #c4c4c4;
}
.cr-about-content h4.elementor {
  font-size: 48px;
}
.cr-about-content .elementor-suffix {
  margin-left: 5px;
  font-size: 16px;
}