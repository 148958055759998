/* Wishlist */

.cr-wishlist-msg {
    padding: 30px 0;
    text-align: center;
    font-size: 50px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: $text-999;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
    .cr-wishlist-msg {
        font-size: 35px;
    }
}
@media only screen and (max-width: 767px) {
    .cr-wishlist-msg {
        font-size: 25px;
    }
}
@media only screen and (max-width: 575px) {
    .cr-wishlist-msg {
        font-size: 18px;
    }
}