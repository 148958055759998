/* elemets-products */
.cr-product-csc{
    transition: all 0.4s ease-out;
    background-color: $fff;
    font-family: $font-family-Poppins;
    text-align: center;
    border: 1px solid $border-color;
    overflow: hidden;
    .m-15{
        margin: 15px 15px 0 15px;
    }
    .cr-side-view {
        position: absolute;
        top: 12px;
        right: 12px;
        a {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 1px solid $border-color;
            border-radius: 50%;
            &:not(:last-child){
                margin-bottom: 5px;
            }
        }
    }
    .cr-product-image{
        transition: all 0.3s linear;
        background-color: $off-white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            width: 150px;
        }
        .cr-shopping-bag {
            bottom: 12px;
            right: 12px;
            background-color: #fff;
        }
        .cr-product-base{
            transition: box-shadow 0.3s;
            width: 45px;
            height: 25px;
            position: absolute;
            top: 15px;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase !important;
            color: $fff;
            background-color: $tools-color;
            z-index: 10;
        }
    }
    .cr-product-details{
        padding: 15px 15px 0 15px;
        position: relative;
        background-color: $fff;
        p {
            margin-bottom: 5px;
        }
        p.cr-price {
            margin-bottom: 0;
        }
        a {
            font-weight: 500;
        }
    }
}

