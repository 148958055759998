/* Deal */
.bg-banner-deal {
    width: 100%;
    height: 600px;
    position: relative;
    background-image: url("../img/deal/bg-deal.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cr-deal-rightside {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .cr-deal-content {
        max-width: 500px;
        padding: 24px;
        background-color: rgba(255, 255, 255, .22);
        border-radius: $radius;
        box-shadow: -3px 3px 8px 5px rgba(0, 0, 0, .05);
        text-align: left;
        span {
            font-family: $font-family-Poppins;
            font-size: 16px;
            font-weight: 400;
            code {
                font-size: 26px;
                font-weight: 900;
                line-height: 1.2;
                color: $main-primary-color;
            }
        }
        h4.cr-deal-title {
            margin-top: 12px;
            margin-bottom: 15px;
            font-family: $font-family-Manrope;
            font-size: 28px;
            font-weight: bold;
            line-height: 1.2;
            color: $title-color;
        }
        p {
            font-size: 14px;
            font-weight: 300;
        }
    }
}

.cr-counter {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    .cr-counter-inner {
        padding: 12px 24px;
        display: flex;
        border: 1px solid #e9cbbe;
        border-radius: $radius;
        h4 {
            width: 45px;
            margin: 0;
            position: relative;
            display: grid;
            font-family: $font-family-Poppins;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 400;
            color: $text-color;
            text-align: center;
            span {
                font-family: $font-family-Poppins;
                font-size: 20px;
                font-weight: bold;
                line-height: 1.2;
                color: $title-color;
            }
            &:not(:first-child) {
                margin-left: 20px;
                &::after {
                    content: ":";
                    font-size: 25px;
                    color: $text-color;
                    position: absolute;
                    right: 50px;
                    top: 5px;
                }
            }
        }
    }
}

/* Responsive */
@media only screen and (max-width: 991px) {
    .cr-deal-rightside {
        .cr-deal-content {
            max-width: 400px;
            h4.cr-deal-title {
                font-size: 22px;
            }
        }
    }
    .cr-counter {
        .cr-counter-inner {
            h4 {
                span {
                    font-size: 18px;
                }
            }
        }
    }
    .bg-banner-deal, .cr-deal-rightside {
        height: 500px;
    }
}
@media only screen and (max-width: 767px) {
    .cr-deal-rightside {
        .cr-deal-content{
            max-width: 100%;
        }
    }
    
}

@media only screen and (max-width: 575px) {
    .cr-deal-rightside {
        .cr-deal-content {
            h4.cr-deal-title {
                font-size: 22px;
            }
            span {
                code {
                    font-size: 22px;
                }
            }
        }
    }
    .cr-deal-rightside {
        .cr-deal-content {
            max-width: 100%;
            text-align: center;
            .cr-counter {
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 390px) {

    .cr-products-rightbar {
        .cr-products-rightbar-content {
            .cr-unique {
                display: none;
            }
        }
    }

    .cr-deal-rightside {
        .cr-deal-content {
            padding: 15px;
        }
    }

    .cr-counter {
        .cr-counter-inner {
            h4 {
                font-size: 12px;
                span{
                    font-size: 16px;
                }
            }
        }
    }

    .cr-counter {
        .cr-counter-inner {
            h4 {
                &:not(:first-child) {
                    margin-left: 5px;
                    &::after{
                        font-size: 20px;
                        top: 5px;
                        right: 43px;
                    }
                }
            }
        }
    }

    .cr-products-rightbar {
        .cr-products-rightbar-content {
            h4{
                font-size: 17px;
            }
            .cr-off{
                margin-top: 10px;
                span {
                    font-size: 17px;
                }
            }
            .rightbar-buttons{
                margin-top: 20px;
            }
        }
    }

    .cr-products-leftbar {
        .cr-products-leftbar-content {
            h2{
                font-size: 20px;
            }
            h4{
                font-size: 20px;
            }
            span{
                font-size: 15px;
            }
        }
    }

}
@media only screen and (max-width: 360px) {
    .bg-banner-deal, .cr-deal-rightside {
        height: 450px;
    }
}