/* contact-us */
.cr-info-box {
    padding: 24px;
    background-color: $fff;
    border: 1px solid $border-color;
    border-radius: $radius;
    text-align: center;
    .cr-icon {
        i {
            font-size: 30px;
            color: $main-primary-color;
        }
    }
    .cr-info-content {
        h4.heading {
            font-family: $font-family-Manrope;
            font-size: 18px;
            font-weight: bold;
            color: $title-color;
        }
        p {
            margin: 0;
            font-size: 14px;
            color: $text-777;
        }
    }
}

iframe {
    width: 100%;
    height: 100%;
    margin-bottom: -7px;
    border: 1px solid $border-color;
    border-radius: 5px;
}

.cr-content-form {
    .form-group {
        margin-bottom: 24px;
        .cr-form-control {
            width: 100%;
            padding: 0.575rem 0.75rem;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            color: $black;
            border: 1px solid $border-color;
            outline: none;
            appearance: none;
            border-radius: 5px;
            &::placeholder {
                font-size: 14px;
                color: $text-777;
            }
        }
    }
}

/* Responsive */
@media only screen and (max-width: 991px) {

    .cr-info-box {
        .cr-info-content {
            h4.heading {
                font-size: 22px;
            }
            p {
                font-size: 13px;
            }
        }
    }

}

@media only screen and (max-width: 767px) {

    .cr-info-box {
        max-width: 350px;
        margin: auto;
    }

}

@media only screen and (max-width: 575px) {

    .cr-info-box {
        max-width: 300px;
        margin: auto;
    }
    
}