@charset "UTF-8";

/*-----------------------------------------------------------------------------------

    Item Name: Snekam Export Trading - Multipurpose eCommerce HTML Template.
    Author: ashishmaraviya
    Version: 2.0
    Copyright 2024

-----------------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------------
    Included CSS INDEX
-----------------------------------------------------------------------------------

01. Typography

02. Utilities
    - Variables
    - Mixins

03. Components
    - Common

04. layout
    - Header
    - Footer

05. pages 
   - Hero
   - Categories
   - Product-banner
   - Popular-product
   - Deal
   - Testimonial
   - Blog
   - Product-page
   - Shop
   - Blog-classic
   - Blog-details
   - Faq
   - About
   - Services
   - Cart
   - Login
   - Register
   - Elements-products
   - Elements-typography
   - Wishlist
   - Track order
   - Checkout
   - Terms Policy

-----------------------------------------------------------------------------------*/

/* Typography */
@import "base/_typography";

/* Utilities */
@import "utilities/_variables";
@import "utilities/_mixing";

/* Components */
@import "components/_common";
@import "components/_footer";
@import "components/_header";

/* layout */
@import "layout/_hero";
@import "layout/_sidebar-tools";
@import "layout/_cart-sidebar";
@import "layout/_deal";
@import "layout/_testimonial";
@import "layout/_popular-product";
@import "layout/_product-banner";
@import "layout/_categories";
@import "layout/_services";
@import "layout/_blog";
@import "layout/_quickview";

/* pages */
@import "pages/_product-page";
@import "pages/_shop";
@import "pages/_blog-classic";
@import "pages/_blog-details";
@import "pages/_faq";
@import "pages/_about";
@import "pages/_contact-us";
@import "pages/_cart";
@import "pages/_login";
@import "pages/_register";
@import "pages/_elements-products";
@import "pages/_elements-typography";
@import "pages/_elements-buttons";
@import "pages/_wishlist";
@import "pages/_track-order";
@import "pages/_checkout";
@import "pages/_terms-policy";

@import url('https://fonts.googleapis.com/css2?family=Gibson+2019&display=swap');










