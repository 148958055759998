/* Login */
.cr-login {
    max-width: 400px;
    margin: auto;
    padding: 30px;
    background-color: $fff;
    border: 1px solid $border-color;
    border-radius: $radius;

    .cr-content-form {
        .form-group {
            label {
                margin-bottom: 9px;
                color: $text-444;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1;
            }
        }

        .remember {
            color: $text-777;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 15px;

            input {
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }

            .link {
                color: $text-777;
            }
        }

        .custom {
            margin: 0;
            display: flex;
            align-items: center;

            input {
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                display: none;
                cursor: pointer;

                &:checked+label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 6px;
                    width: 6px;
                    height: 11px;
                    border: solid $main-primary-color;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }

            label {
                margin: 0;
                position: relative;
                cursor: pointer;
                color: $text-777;
                font-weight: 400;

                &:before {
                    content: '';
                    margin-right: 10px;
                    padding: 8px;
                    background-color: transparent;
                    border: 1px solid $border-color;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    border-radius: $radius;
                }
            }
        }

        .login-buttons {
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            button {
                margin-right: 15px;
                padding: 8px 20px;
            }

            .link {
                padding: 8px 0;
                color: $text-777;
            }
        }
    }
}

.form-logo {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive */
@media only screen and (max-width: 420px) {
    .cr-login {
        .cr-content-form {
            .remember {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: baseline;
            }
            .custom {
                label {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
