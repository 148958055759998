/*-------------------------------------------------
  Checkout Page CSS
---------------------------------------------------*/
.cr-checkout-section {
  font-size: 14px;
  label {
    margin-bottom: 15px;
  }
  [type="radio"] {
    &:checked {
      position: relative;
      opacity: 0;
      display: none;
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 16px;
          display: inline-block;
          color: $text-color;
          letter-spacing: 0;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #ffffff;
            border: 1px solid $main-primary-color;
          }
          &:after {
            content: "";
            width: 8px;
            height: 8px;
            background: $main-primary-color;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
    &:not(:checked) {
      position: relative;
      opacity: 0;
      display: none;
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 16px;
          display: inline-block;
          color: $text-color;
          letter-spacing: 0;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #ffffff;
          }
          &:after {
            content: "";
            width: 8px;
            height: 8px;
            background: $main-primary-color;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }
      }
    }
  }
  }
  .cr-checkout-wrap {
    padding: 24px;
    border: 1px solid $border-color;
    background-color: $fff;
    border-radius: $radius;
    h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 25px;
      position: relative;
      display: block;
      color: $title-color;
      font-family: 'Montserrat';
      line-height: 1;
    }
    .cr-check-login {
      h3 {
        margin-bottom: 22px;
      }
    }
    .cr-check-bill {
      h3 {
        margin-bottom: 26px;
        line-height: 1.2;
      }
      .cr-check-subtitle {
        margin-bottom: 10px;
      }
    }
  }
  .cr-sidebar-wrap {
    border: 1px solid $border-color;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: $radius;
    background: #fff;
    &:last-child {
      margin-bottom: 0;
    }
    .cr-sb-block-content {
      margin-bottom: 0;
      border-bottom: none;
    }
    .cr-sb-title {
      h3 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 0;
        position: relative;
        color: $black;
        .cr-sidebar-res {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
    }
  }
  .cr-sb-block-content {
    margin-top: 15px;
  }
  .cr-checkout-pay-wrap {
    [type="radio"] {
      &:checked {
        + {
          label {
            margin-bottom: 0;
          }
        }
      }
      &:not(:checked) {
        + {
          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .payment-options {
    display: flex;
    flex-direction: column;
    .cr-pay-option {
      &:not(:last-child){
        margin-bottom: 10px;
      }
      }
  }
  .cr-new-product {
    margin-top: 2px;
  }
  .cr-check-subtitle {
    margin-bottom: 14px;
    color: $title-color;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
  }
  .cr-checkout-block.cr-check-new {
    margin-bottom: 30px;
  }
  .cr-new-option {
    span {
      margin-right: 45px;
    }
  }
  .cr-new-desc {
    position: relative;
    line-height: 24px;
    color: $text-color;
    letter-spacing: 0;
    max-width: 64%;
  }
  .cr-new-btn {
    margin-top: 26px;
    a {
      height: 40px;
      text-transform: uppercase;
      font-size: 16px;
      min-width: 150px;
      line-height: 40px;
      letter-spacing: 0;
    }
  }
  .cr-check-login-form {
    form {
      display: flex;
      flex-direction: column;
    }
    label {
      margin-bottom: 11px;
      color: $title-color;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1;
    }
    input {
      height: 50px;
      background-color: transparent;
      border: 1px solid $border-color;
      color: $title-color;
      font-size: 14px;
      margin-bottom: 16px;
      padding: 0 15px;
      width: 100%;
      outline: none;
      border-radius: $radius;
    }
    .cr-check-login-fp {
      color: $title-color;
      font-weight: 600;
      letter-spacing: 0.8px;
      text-decoration: underline;
      font-size: 15px;
      line-height: 24px;
    }
    .cr-check-login-btn {
      display: flex;
      align-items: center;
      margin-top: 14px;

      .cr-btn-2 {
        margin-right: 20px;
      }
    }
  }

  .cr-bill-option {
    margin-bottom: 24px;
    display: block;
    span {
      margin-right: 45px;
    }
  }
  .padding-bottom-3 {
    padding-bottom: 3px !important;
  }
  /*-----  Checkout Page CSS  ------*/
  .cr-checkout-wrap {
    .cr-check-bill-form {
      .cr-bill-wrap {
        width: 100%;
        padding: 0 15px;
      }
      .cr-bill-wrap.cr-bill-half {
        width: 50%;
      }
      form {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -15px;
      }
      label {
        margin-bottom: 7px;
        color: $title-color;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1;
      }
      input {
        height: 50px;
        background-color: transparent;
        border: 1px solid $border-color;
        color: $title-color;
        font-size: 14px;
        margin-bottom: 26px;
        padding: 0 15px;
        width: 100%;
        outline: none;
        border-radius: $radius;
      }
      .cr-bill-fp {
        label {
          margin-bottom: 0;

          a {
            color: $title-color;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0px;
          }
        }
      }
      .cr-bl-select-inner {
        position: relative;
        display: flex;
        width: 100%;
        height: 50px;
        border-radius: 0;
        border: 1px solid $border-color;
        font-size: 14px;
        padding: 0;
        margin-bottom: 24px;
        border-radius: $radius;
        select {
          padding: 0 15px;
          appearance: none;
          outline: 0;
          box-shadow: none;
          border: 0 !important;
          background: $fff;
          background-image: none;
          flex: 1;
          padding: 0 10px;
          color: $text-color;
          cursor: pointer;
          border-radius: $radius;
        }
        &::after {
          content: "\ea4e";
          position: relative;
          font-family: "remixicon";
          top: 0;
          right: 0;
          padding: 0 10px;
          cursor: pointer;
          pointer-events: none;
          font-size: 24px;
          display: flex;
          height: 50px;
          line-height: 1;
          justify-content: center;
          align-items: center;
          color: $text-color;
        }
      }
    }
  }

  .cr-checkout-content {
    .cr-check-order-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      .btn {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        font-size: 16px;
        line-height: 1;
        padding: 0 5px;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        outline: none;
        border-radius: 0px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0;
      }
    }
  }
  .cr-checkout-summary {
    >* {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .text-left {
      color: $text-color;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
    }
    .text-right {
      color: $black;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      a {
        color: $main-primary-color;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .cr-checkout-summary-total {
      border-top: 1px solid $border-color;
      padding-top: 19px;
      margin-bottom: 0;
      margin-top: 16px;
      .text-left {
        font-size: 16px;
        font-weight: 600;
        color: $title-color;
        font-family: $font-family-Manrope;
      }
      .text-right {
        font-size: 16px;
        font-weight: 600;
        font-family: $font-family-Manrope;
      }
    }
  }

  /*-----  Sidebar Page CSS  ------*/
  .cr-sidebar-wrap {
    .cr-sidebar-block {
      .cr-checkout-pro {
        margin-top: 34px;
        > div {
          &:not(:last-child){
            margin-bottom: 15px;
          }
        }
      }
      .cr-sb-block-content {
        .cr-checkout-pro {
          li {
            padding: 1px;
            a {
              margin: 0 auto;
              display: block;
              color: $text-color;
              font-size: 12px;
              margin-top: 0;
              line-height: 18px;
              font-weight: 400;
              text-transform: capitalize;
              letter-spacing: 0;
              cursor: pointer;
            }
          }
          .cr-pro-size {
            li {
              a {
                pointer-events: none;
                width: 18px;
                height: 18px;
                cursor: pointer;
                background-color: $border-color;
                color: $text-color;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                line-height: 18px;
                padding-top: 2px;
              }
            }
            li.active {
              a {
                background-color: $black;
                color: $fff;
              }
            }
          }
          .cr-product-inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            .cr-pro-content {
              width: calc(100% - 143px);
              padding: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              border: 0;
              i {
                color: #f4a263;
              }
              h5 {
                padding: 0 15px 0 0;
                a {
                  font-size: 15px;
                }
              }
            }
            .cr-pro-content.cr-product-details {
              justify-content: flex-start;
              h5 {
                text-align: left;
              }
              .cr-pro-rating {
                margin-bottom: 5px;
                text-align: left;
              }
              .cr-price {
                text-align: left;
              }
            }
            .cr-pro-image-outer {
              width: 80px;
              margin-right: 15px;
              box-shadow: none;
              .cr-pro-image {
                overflow: hidden;
                img {
                  max-width: 100%;
                }
            }
            }
          }
        }
        .cr-del-option {
          display: flex;

          >* {
            width: 50%;
          }
        }
        .cr-del-opt-head {
          color: $title-color;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1;
          margin-bottom: 10px;
          display: block;
        }
        .cr-del-desc {
          color: $text-color;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 15px;
          display: block;
          letter-spacing: 0;
        }
        .cr-pay-opt-head {
          color: $title-color;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1;
          margin-bottom: 6px;
          display: block;
        }
        .cr-pay-desc {
          color: $text-color;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 15px;
          display: block;
          letter-spacing: 0;
        }
      }
    }
  }
  .cr-product-inner {
    .cr-pro-content {
      .cr-price {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
  .cr-sidebar-wrap.cr-checkout-del-wrap {
    h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 0;
    }
    .cr-del-commemt {
      margin-top: 12px;
      display: block;
      .cr-del-opt-head {
        margin-bottom: 8px;
      }
    }
    textarea {
      background: transparent;
      border: 1px solid $border-color;
      color: $title-color;
      height: 100px;
      padding: 10px 15px;
      margin-bottom: 0;
      width: 100%;
      outline: none;
      font-size: 14px;
      display: block;
      border-radius: $radius;
    }
  }
  .cr-sidebar-wrap.cr-checkout-pay-wrap {
    h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 0;
    }
    textarea {
      background: transparent;
      border: 1px solid $border-color;
      color: $title-color;
      height: 110px;
      padding: 10px 15px;
      margin-bottom: 0;
      width: 100%;
      outline: none;
      font-size: 14px;
      display: block;
      border-radius: $radius;
    }
    .cr-pay-commemt {
      .cr-pay-opt-head {
        margin-bottom: 8px;
      }
    }
    .cr-pay-agree {
      position: relative;
      display: block;
      margin-top: 20px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 999;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        &:checked {
          ~ {
            .checked {
              &::after {
                display: block;
              }
            }
          }
        }
      }
      a {
        margin-left: 27px;
        display: block;
        color: $text-color;
        font-size: 14px;
        margin-top: 0;
        line-height: 18px;
        font-weight: 300;
        letter-spacing: 0;
        cursor: pointer;
        span {
          font-weight: 500;
          color: $title-color;
        }
      }
      .checked {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #ffffff;
        border: 1px solid #ededed;
        border-radius: 4px;
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear;
        &:after {
          left: -1px;
          top: -1px;
          width: 15px;
          height: 15px;
          border: 1px solid $main-primary-color;
          content: "\eb7b";
          position: relative;
          display: none;
          font-family: "remixicon";
          font-size: 13px;
          color: $main-primary-color;
          line-height: 14px;
          text-align: center;
          border-radius: 4px;
        }
      }
    }
  }
  .cr-sidebar-wrap.cr-check-pay-img-wrap {
    h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 0;
    }
  }
  .cr-check-pay-img-wrap {
    .cr-check-pay-img-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    }
    .cr-check-pay-img {
      img {
        width: 100%;
      }
    }
  }

/*  Responsive  */
@media only screen and (max-width: 991px) {
  .cr-checkout-rightside {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .cr-checkout-wrap {
    .cr-check-bill-form {
      .cr-bill-wrap.cr-bill-half {
        width: 100%;
    }
    }
  }
  .cr-checkout-wrap {
    h3 {
      font-size: 18px;
  }
  }
  .cr-new-desc {
    max-width: 100%;
  }
}