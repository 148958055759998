.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    padding: 15px;
    z-index: 1000;
  }
  
  .whatsapp-button:hover {
    background-color: #25d366;
  }
  