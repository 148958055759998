/* cart */
.cr-table-content {
    table {
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 5px;
        overflow: hidden;
        thead {
            tr {
                border: 1px solid $border-color;
                th {
                    padding: 15px;
                    border-top: medium none;
                    color: $text-444;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: left;
                    text-transform: capitalize;
                    vertical-align: middle;
                    white-space: nowrap;
                    line-height: 1;
                    letter-spacing: 0;
                    background-color: #e9e9e9;
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid $border-color;
                .cr-cart-name {
                    width: 40%;
                    a {
                        color: $text-444;
                        font-weight: 500;
                        text-decoration: none;
                        font-size: 14px;
                        display: flex;
                        line-height: 1.5;
                        letter-spacing: 0.6px;
                        align-items: center;
                    }
                }
                .cr-cart-price {
                    color: $text-555;
                    font-size: 15px;
                    font-weight: 500;
                }
                .cr-cart-subtotal {
                    color: $text-555;
                    font-weight: 500;
                    font-size: 15px;
                }
                .cr-cart-remove {
                    width: 90px;
                    text-align: right;
                    a {
                        margin: 0 auto;
                        color: $text-555;
                        &:hover {
                            color: $red;
                        }
                        i {
                            font-size: 22px;
                        }
                    }
                }
                td {
                    padding: 25px 14px;
                    color: $text-444;
                    font-size: 16px;
                    text-align: left;
                    background-color: $off-white;
                    .cr-cart-img {
                        width: 60px;
                        margin-right: 20px;
                        border: 1px solid $border-color;
                        border-radius: $radius;
                    }
                }
            }
        }
    }
}

.cr-cart-qty {
    text-align: center;
    .cart-qty-plus-minus {
        width: 80px;
        height: 30px;
        margin: 0 auto;
        position: relative;
        display: inline-block;
        overflow: hidden;
        display: flex;
        background-color: $fff;
        border: 1px solid $border-color;
        border-radius: 5px;                         
        align-items: center;
        justify-content: space-between;
        input {
            width: 30px;
            margin: 0;
            padding: 0;
            background: transparent none repeat scroll 0 0;
            color: $text-444;
            border: medium none;
            float: left;
            font-size: 14px;
            font-weight: 600;
            line-height: 38px;
            height: auto;
            text-align: center;
            outline: none;
        }
        button {
            height: 25px;
            width: 25px;
            margin-top: -2px;
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.cr-cart-update-bottom {
    padding: 30px 0 0;
    display: flex;
    justify-content: space-between;
    a.cr-links {
        color: $text-444;
        display: inline-block;
        text-decoration: underline;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.8px;
    }
}

/* Responsive */
@media only screen and (max-width: 767px) {
    .cr-cart-content {
        overflow-x: scroll;
        .row {
            width: 700px;
        }
    }
}

@media only screen and (max-width: 575px) {
    .cr-cart-view {
        .cr-cart-inner{
            width: 300px;
        }
    }
}
@media only screen and (max-width: 360px) {
    .cr-cart-view {
        .cr-cart-inner {
            width: 280px;
        }
    }
}