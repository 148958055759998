/* Sidebar Tools */
/* Side-tool */
.cr-tool-active {
    right: 0 !important;
}

.cr-tool-overlay {
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $black_700;
}

.cr-tool {
    transition: all 0.4s ease;
    position: fixed;
    top: 0;
    right: -230px;
    z-index: 20;
    color: $black;
    .cr-tool-btn {
        display: flex;
        text-decoration: none;
        color: $black;
        i {
            font-size: 22px;
        }
        .btn-cr-tool {
            transition: all ease-in 0.3s;
            height: 43px;
            width: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 30%;
            right: 230px;
            border: none;
            background: none;
            background-color: $tools-color;
            color: $fff;
            border-radius: 5px;
            box-shadow: $card_shadow;
        }
        .color-variant {
            width: 230px;
            height: 100vh;
            padding: 0;
            margin: 0;
            background-color: $fff;
            .cr-bar-title {
                margin-bottom: 15px;
                padding: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color;
                h6 {
                    margin: 0;
                    font-size: 17px;
                    font-weight: 700;
                    color: $title-color;
                }
                .close-tools {
                    color: $red;
                    font-size: 17px;
                }
            }
            .cr-tools-detail {
                padding: 0 15px 15px 15px;
                border-bottom: 1px solid $border-color;
                .heading {
                    padding: 15px 0;
                    h2 {
                        margin-bottom: 0;
                        font-family: $font-family-Poppins;
                        font-size: 14px;
                        font-weight: 600;
                        color: $title-color !important;
                    }
                }
                ul.cr-color {
                    margin: 0;
                    padding: 0;
                    li {
                        height: 36px;
                        width: 36px;
                        margin: 5px 5px;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .c1 {
                        background-color: $main-primary-color;
                    }
                    .c2 {
                        background-color: $color-1;
                    }
                    .c3 {
                        background-color: $color-2;
                    }
                    .c4 {
                        background-color: $color-3;
                    }
                    .c5 {
                        background-color: $color-4;
                    }
                    .c6 {
                        background-color: $color-5;
                    }
                    .c7 {
                        background-color: $color-6;
                    }
                    .c8 {
                        background-color: $color-7;
                    }
                    .c9 {
                        background-color: $color-8;
                    }
                    .c10 {
                        background-color: $color-9;
                    }
                }
                ul.dark-mode {
                    margin: 0;
                    padding: 0;
                    li {
                        height: 85px;
                        width: 85px;
                        margin: 5px 5px;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 5px;
                        cursor: pointer;
                        box-shadow: $black_300;
                    }
                    li.dark {
                        background-color: $black;
                        color: $fff;
                    }
                    li.white {
                        background-color: $fff;
                        color: $black;
                    }
                }
                ul.bg-panel {
                    margin: 0;
                    padding: 0;
                    li {
                        height: 85px;
                        width: 85px;
                        margin: 5px 5px;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                        border-radius: 5px;
                        cursor: pointer;
                        img {
                            height: 85px;
                            width: 85px;
                            border: 1px solid $border-color;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}

.active-bg-panel {
    position: relative;
    &:before {
        width: 20px;
        height: 20px;
        padding: 0;
        font-family: remixicon;
        content: "\EB79";
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $main-primary-color;
        color: $fff;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.active-dark-mode {
    position: relative;
    &:before {
        width: 20px;
        height: 20px;
        padding: 0;
        font-family: remixicon;
        content: "\EB79";
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $main-primary-color;
        color: $fff;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.active-colors {
    &:before {
        padding: 0;
        font-family: remixicon;
        content: "\EB79";
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        height: 36px;
        justify-content: center;
        color: $fff;
    }
}