/* variables */
/* Font Family */
$font-family-Poppins: "Poppins, sans-serif";
$font-family-Manrope: 'Manrope', sans-serif;

/* color */
$main-primary-color: #64b496;
$black: #000;
$fff: #fff;
$ddd: #ddd;
$text-777: #777;
$text-999: #999;
$text-444: #444;
$text-555: #555;
$text-eee: #eee;
$title-color: #2b2b2d;
$text-color: #7a7a7a;
$yellow: #dede44;
$Orange: #ffa500;
$red: #fb5555;
$blue: #6c9eff;
$off-blue: #3474d4;
$tools-color: #373840;

$black_700: rgba(0, 0, 0, 0.7);
$black_600: rgba(0, 0, 0, 0.6);
$black_500: rgba(0, 0, 0, 0.5);
$black_400: rgba(0, 0, 0, 0.4);

$card_shadow: 0px 4px 17.64px 0.36px rgba(0, 0, 0, 0.1);
$checkbox_shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
$black_300: 0 0 10px 0 rgba(0, 0, 0, 0.3);

$success: #64b496;
$info: #0dcaf0;
$blue_100 : #e0e5ec;
$star: #ffc253;
$color-1: #f5885f;
$color-2: #ae8d62;
$color-3: #3f51b5;
$color-4: #f44336;
$color-5: #e91e4c;
$color-6: #607d8b;
$color-7: #5392d2;
$color-8: #03492f;
$color-9: #9655ca;

/* border-color */
$border-color: #e9e9e9;
$card-border-color: #e9e9e9;
$off-white: #f7f7f8;
$radius: 5px;

/* btn shadown */
$btn_1_shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #000,
    4px 4px 5px 0px #000;

$btn_2_shadow:
    4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);

/* shadow */
$black_shadow_0: #7963e000;

$box_shadow_0:
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0;

$box_shadow_12:
    100px 0 $main-primary-color,
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0;

$box_shadow_25:
    110px 0 $main-primary-color,
    100px 0 $main-primary-color,
    0px 0 $black_shadow_0,
    0px 0 $black_shadow_0;

$box_shadow_36:
    120px 0 $main-primary-color,
    110px 0 $main-primary-color,
    100px 0 $main-primary-color,
    0px 0 $black_shadow_0;

$box_shadow_50:
    130px 0 $main-primary-color,
    120px 0 $main-primary-color,
    110px 0 $main-primary-color,
    100px 0 $main-primary-color;

$box_shadow_62:
    200px 0 $black_shadow_0,
    130px 0 $main-primary-color,
    120px 0 $main-primary-color,
    110px 0 $main-primary-color;

$box_shadow_75:
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0,
    130px 0 $main-primary-color,
    120px 0 $main-primary-color;

$box_shadow_87:
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0,
    130px 0 $main-primary-color;

$box_shadow_100:
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0,
    200px 0 $black_shadow_0;
