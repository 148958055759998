/* Header */
header {
    height: 142px;
    background-color: #fff;
    border-bottom: 1px solid $border-color;
}
.cr-fix.menu_fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    -webkit-box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
    background: #ffffff;
    -webkit-transition: top 1s;
    transition: top 1s;
    .top-header {
        display: none;
    }
}
.cr-fix.menu_fixed_up {
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    z-index: 10;
    -webkit-box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
    background: #ffffff;
    -webkit-transition: top 1s;
    transition: top 1s;
    .top-header {
        display: none;
    }
}
.cr-menu-list {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    .cr-category-dropdown {
        button {
            padding: 12px 30px;
            border: none;
            font-family: $font-family-Poppins;
            font-size: 14px;
            background-color: $main-primary-color;
            border-radius: $radius;
            color: $fff;

            &:after {
                margin-left: 40px;
                font-size: 16px;
                vertical-align: 2px;
            }

            &::before {
                margin-right: 20px;
                font-family: remixicon !important;
                content: "\EF31";
                font-size: 16px;
            }
        }

        .dropdown-menu {
            transition: all 0.3s ease-in-out;
            width: 235px;
            margin-top: 20px;
            position: absolute;
            visibility: hidden;
            display: block;
            opacity: 0;
        }

        &:hover {
            .dropdown-menu {
                opacity: 1;
                visibility: visible;
                margin-top: 1px;
            }
        }
    }

    nav {
        padding: 0;

        ul {
            li {
                &:not(:last-child) {
                    margin-right: 25px;
                }

                a {
                    font-family: $font-family-Poppins;
                    font-size: 13px;
                    color: $text-777;
                }
            }
        }
    }

    .cr-calling {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        align-items: center;

        i {
            padding-right: 5px;
            font-size: 20px;
        }

        a {
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.top-header {
    padding: 9px 0 5px 0;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    display: flex;
    position: relative;
    z-index: 4;

    .cr-logo {
        img {
            width: 215px;
            height: 85px;
        }
    }

    .cr-search {
        position: relative;
        select {
            width: 130px;
            height: calc(100% - 2px);
            border: 0;
            position: absolute;
            top: 1px;
            right: 45px;
            font-size: 13px;
            border-left: 1px solid $main-primary-color;
            border-radius: 0;
            &:focus {
                box-shadow: none;
            }
        }
        input {
            width: 600px;
            height: 45px;
            padding-left: 15px;
            padding-right: 175px;
            border: 1px solid $main-primary-color;
            border-radius: 5px;
            outline: none;

            &::placeholder {
                font-family: $font-family-Poppins;
                font-size: 13px;
                color: $text-color;
            }
        }

        .search-btn {
            width: 45px;
            background-color: $main-primary-color;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 14px;
                color: $fff;
            }
        }
    }

    .cr-right-bar {
        display: flex;

        ul.navbar-nav {
            li.nav-item {
                .dropdown-toggle {

                    &:after,
                    &:before {
                        display: none;
                    }
                }
            }

            .dropdown {
                .dropdown-menu {
                    li {
                        a {
                            color: $text-777;
                            font-size: 13px;
                            font-weight: 400;

                            &:hover {
                                color: $main-primary-color;
                            }
                        }
                    }
                }
            }
        }

        .cr-right-bar-item {
            transition: 0.3s ease-in-out;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                padding-right: 30px;
            }

            &:hover {
                color: $main-primary-color;

                span {
                    color: $main-primary-color;
                }
            }

            span {
                transition: 0.3s ease-in-out;
                font-family: $font-family-Poppins;
                font-size: 15px;
                line-height: 15px;
                font-weight: 500;
                color: $black;
            }

            i {
                padding-right: 5px;
                font-size: 21px;
                line-height: 17px;
            }
        }
    }
}

.navbar-toggler {
    padding: 7px 14px;
    font-size: 16px;
}

ul.navbar-nav {
    margin: auto;
    position: relative;
    z-index: 3;

    li.nav-item {
        position: relative;

        &:hover {
            color: $main-primary-color;

            a.nav-link {
                &::before {
                    width: 100%;
                    background-color: $main-primary-color;
                }
            }
        }

        a.nav-link {
            padding: 11px 8px;
            font-family: $font-family-Poppins;
            font-size: 14px;
            font-weight: 500;
            color: $black;
            z-index: 1;
            position: relative;

            &::before {
                transition: all 0.3s ease 0s;
                height: 2px;
                width: 0;
                margin: 0 auto;
                content: "";
                position: absolute;
                top: auto;
                bottom: -6px;
                background-color: $main-primary-color;
                left: 0;
                right: auto;
            }
        }

        .dropdown-toggle {
            display: flex;
            align-items: center;

            &::after {
                margin-left: 5px;
                font-family: remixicon;
                content: "\EA4E";
                border: none;
                vertical-align: 0;
                display: flex;
                align-items: center;
                font-size: 17px;
                line-height: 15px;
            }
        }
    }

    .dropdown {
        .dropdown-menu {
            transition: all 0.3s ease-in-out;
            min-width: 160px;
            margin-top: 35px;
            position: absolute;
            text-align: left;
            opacity: 0;
            visibility: hidden;
            left: auto !important;
            background-color: $fff;
            box-shadow: $card_shadow;
            border-radius: 5px;
            border: 0;
            display: block;
            z-index: 9;
            border: 1px solid $border-color;
            li {
                width: 100%;
                margin-right: 0;

                a {
                    @include ease3;
                    padding: 7px 20px;
                    background-color: $fff;
                    position: relative;
                    text-transform: capitalize;
                    &:hover {
                        color: $main-primary-color;
                    }

                    i {
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }

        &:hover {
            .dropdown-menu {
                margin-top: 6px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/* Category Toggle */
.cr-category-icon-block {
    padding: 10px 0;
    position: static !important;

    &:hover {
        .cr-cat-dropdown {
            margin-top: 10px;
            opacity: 1;
            visibility: inherit;
        }
    }
}

.cr-category-menu {
    position: relative;

    .cr-category-toggle {
        width: 35px;
        height: 35px;
        border: 1px solid $border-color;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 22px;
            color: $title-color;
            line-height: 0;
            line-height: 14px;
            display: block;
        }

        i.ri-arrow-down-s-line {
            position: absolute;
            right: 5px;
        }

        .cr-category-toggle {
            width: 200px;
            min-height: 50px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            background-color: $main-primary-color;
            border-radius: 5px;
            cursor: pointer;

            .cr-angle {
                font-size: 18px;
                text-transform: uppercase;
                color: $fff;
                text-align: center;
                position: absolute;
                right: 15px;
                line-height: 0;
            }
        }
    }
}

.cr-cat-dropdown {
    transition: all 0.3s ease-in-out;
    width: 600px;
    margin-top: 35px;
    padding: 15px;
    position: absolute;
    background-color: $fff;
    opacity: 0;
    visibility: hidden;
    left: 0;
    z-index: 10;
    border-radius: 5px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.071);
    border: 1px solid $border-color;

    .cr-cat-tab {
        display: flex;

        .cr-tab-list {
            min-width: 180px;
            margin-right: 24px;
            border-radius: 5px;
            justify-content: center;
        }

        button,
        a {
            transition: all 0.3s ease-in-out;
            padding: 10px 15px;
            background-color: $fff;
            font-size: 13px;
            color: #4b5966;
            font-weight: 500;
            text-align: left;
            text-transform: capitalize;
            border: 1px solid $border-color;
            border-radius: 5px;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            i {
                transition: all 0.3s ease-in-out;
                margin-right: 10px;
                font-size: 18px;
                line-height: 0;
            }
        }

        button.active {
            color: $main-primary-color;
            background-color: $fff;
            border-color: $main-primary-color;

            i {
                color: $main-primary-color;
            }
        }

        .tab-content {
            transition: all 0.3s ease-in-out;
            width: 100%;

            .tab-list {
                display: flex;

                .col {
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        li {
                            a {
                                height: auto;
                                padding: 5px 0;
                                color: $text-777;
                                text-transform: capitalize;
                                line-height: 26px;
                                font-weight: 400;
                                font-size: 13px;
                                display: block;
                                border: 0;
                            }
                        }
                    }
                }
            }

            .cr-col-title {
                height: auto;
                margin-bottom: 10px;
                padding-bottom: 5px;
                color: $main-primary-color;
                font-size: 15px;
                font-weight: 500;
                line-height: 30px;
                text-transform: capitalize;
                display: block;
                border-bottom: 1px solid $border-color;
            }
        }

        .tab-content> :active {
            display: block;
        }
    }
}

/* Mobile-menu */
.cr-side-cart {
    transition: all 0.5s ease;
    width: 400px;
    height: 100%;
    padding: 15px;
    position: fixed;
    top: 0;
    left: -400px;
    background-color: $fff;
    z-index: 22;
    overflow: auto;
    .cr-menu-title {
        width: 100%;
        margin-bottom: 10px;
        padding: 0 0 10px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 2px solid $border-color;

        .menu-title {
            font-size: 18px;
            font-weight: 600;
            color: $main-primary-color;
        }

        .cr-close {
            position: relative;
            border: 0;
            font-size: 30px;
            line-height: 1;
            color: $text-999;
            background-color: $fff;
        }
    }

    .cr-menu-box {
        height: 45px;
        width: 100%;
        margin: 0;
        padding: 0 10px;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: $text-eee;

        &::placeholder {
            text-transform: capitalize;
            font-size: 15px;
            font-weight: 500;
            color: $text-444;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            line-height: 28px;

            a {
                padding: 12px 0;
                display: block;
                text-transform: capitalize;
                font-size: 15px;
                font-weight: 500;
                color: $text-444;
                text-decoration: none;
                border-bottom: 1px solid $border-color;
            }

            .menu-toggle {
                height: 48px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: transparent;

                &::before {
                    content: "+";
                    font-size: 24px;
                    font-weight: 500;
                    color: $text-777;
                }

                i {
                    transition: all 0.5s ease 0s;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    color: $text-444;
                }
            }

            .sub-menu {
                width: 100%;
                margin-bottom: 0;
                padding: 0;
                box-shadow: none;
                transition: none;
                display: none;
                min-width: auto;
                position: static;
                top: auto;
                visibility: visible;
                opacity: 1;

                li {
                    a {
                        padding-left: 20px;
                        transition: 0.3s ease-in-out;
                        opacity: 0.8;
                        font-size: 14px;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.cr-menu-open {
    left: 0 !important;
}

.navbar-collapse {
    display: none;
}

.cr-sidebar-overlay {
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $black_700;
    z-index: 21;
}

.dark-logo {
    display: none;
}

.logo {
    display: block;
}

.cr-header-buttons {
    display: none;

    a {
        margin-right: 16px;
        transition: 0.3s ease-in-out;

        i {
            font-size: 20px;
        }

        &:hover {
            color: $main-primary-color;
        }
    }
}

/* Responsive */
@media only screen and (max-width: 1399px) {
    .top-header {
        .cr-search input {
            width: 400px;
        }
    }

    .cr-menu-list {
        nav {
            ul {
                li {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .cr-menu-list {
        nav {
            ul {
                margin-right: -5px;

                li {
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }
        }

        .dropdown {
            button {
                padding: 10px;

                &::before {
                    margin-right: 5px;
                }

                &::after {
                    margin-left: 5px;
                }
            }

            .dropdown-menu {
                right: 0;
            }
        }

        .cr-calling {
            display: none;
        }
    }
    ul.navbar-nav {
        li.nav-item {
            a.nav-link {
                padding: 8px 0;
                &:before {
                    bottom: -9px;
                }
            }
        }
        .dropdown:hover {
            .dropdown-menu {
                margin-top: 9px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    header {
        height: 133px;
    }
    .cr-menu-list {
        padding: 0;
    }

    .top-header {
        .cr-right-bar {
            display: none;
        }

        .cr-search {
            input {
                max-width: 350px;
            }
        }
    }

    .cr-menu-list {
        .cr-calling {
            display: none;
        }

        nav {
            width: 100%;

            ul {
                margin: 0;
            }
        }
    }

    ul.navbar-nav {
        .dropdown {
            &:hover {
                .dropdown-menu {
                    margin-top: 0;
                }
            }
        }

        li.nav-item {
            &:hover {
                a.nav-link {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .navbar-toggler {
        padding: 0;
        border: 0;
        i{
            font-size: 20px;
        }
    }

    .cr-category-icon-block {
        display: none;
    }

    .cr-header-buttons {
        display: flex;
        align-items: center;

        a {
            margin: 0;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

}

@media only screen and (max-width: 575px) {
    header {
        height: 173px;
    }
    .top-header {
        padding: 15px 0;
    }

    .top-header {
        .cr-search input {
            width: 300px;
        }
    }

    .cr-side-cart {
        width: 300px;
        left: -300px;
    }
    ul.navbar-nav {
        .dropdown{
            &:hover{
                .dropdown-menu {
                    margin-top: 0;
                }
            }
        }
    }
    .top-header {
        display: block;

        .cr-search {
            max-width: 350px;
            margin: auto;

            input {
                width: 100%;
            }
        }

        .cr-logo {
            margin-bottom: 15px;
            display: flex;
            justify-content: center;

            img {
                width: 100px;
                height: 35px;
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .cr-side-cart {
        width: 250px;
        left: -250px;
    }
    .top-header {
        .cr-search {
            select {
                display: none;
            }
            input {
            padding-right: 45px;
        }
        }
    }
}