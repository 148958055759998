/* Mixing */
@mixin ease1 {
    transition: all .1s ease-in-out;
}

@mixin ease2 {
    transition: all .2s ease-in-out;
}

@mixin ease3 {
    transition: all 0.3s ease-in-out;
}

@mixin ease4 {
    transition: all 0.4s ease-in-out;
}

@mixin ease5 {
    transition: all 0.5s ease-in-out;
}