/* Typography */
/* Manrope */
@font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope', sans-serif;
    src: url("./../font/manrope/Manrope-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
  


/* Poppins */
@font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Light.ttf");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins, sans-serif";
    src: url("./../font/poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }